import { Reset, TransitionPrimary } from '@common/styles'
import { cover } from 'polished'
import styled from 'styled-components'

import { CSSTransition } from 'react-transition-group'
import Icon from '@material-ui/core/Icon'
import React from 'react'
import { createPortal } from 'react-dom'

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  overflow: auto;
`

export const ModalBackdrop = styled.div`
  ${cover()}
  backdrop-filter: blur(4px);
  background-color: ${props => props.theme.colorBackdrop};
`

export const ModalDialog = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${props => props.theme.colorBody};
  padding: 2rem;
  width: 650px;
  margin: 0 1rem;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: ${props => props.theme.shadow};
`

export const ModalBody = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
`

export const ModalHeader = styled.div``
export const ModalTitle = styled.h2`
  margin-bottom: 1rem;
  border-bottom: 1px solid ${props => props.theme.colorLine};
  padding-bottom: 1rem;
`
export const ModalClose = styled.button`
  ${Reset('button')}
  position: absolute;
  top: 1rem;
  right: 1rem;

  span {
    display: block;
    ${TransitionPrimary()}
  }

  &:hover {
    span {
      transform: rotate(90deg);
    }
  }
`
export const ModalContent = styled.div``

const Modal = props => {
  const { title, children, show, onClose } = props
  return createPortal(
    <CSSTransition in={show} timeout={300} classNames="fade-in" unmountOnExit mountOnEnter>
      <ModalWrapper>
        <ModalBackdrop />
        <ModalBody>
          <ModalDialog>
            <ModalTitle>{title}</ModalTitle>
            <ModalContent>{children}</ModalContent>
            <ModalClose onClick={onClose}>
              <Icon>close</Icon>
            </ModalClose>
          </ModalDialog>
        </ModalBody>
      </ModalWrapper>
    </CSSTransition>,
    document.getElementsByTagName('body')[0]
  )
}

export default Modal
