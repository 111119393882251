import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Reset, TransitionPrimary } from '@common/styles'
import styled, { css } from 'styled-components'

import Icon from '@material-ui/core/Icon'
import { flatten } from 'lodash'

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 0.5rem;
`

const SelectField = styled.select`
  display: none;
  position: absolute;
  height: 0;
  width: 0;
`

const SelectLabel = styled.span`
  ${TransitionPrimary('font-size, color, margin-top')}
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate3d(0, -50%, 0);
  padding: 0 ${props => props.theme.inputPadding};
  z-index: 2;
  color: ${props => props.theme.colorMuted};
  pointer-events: none;

  ${props =>
    props.hasValue &&
    css`
      font-size: 0.7em;
      margin-top: -0.65rem;
      color: ${props => props.theme.colorMuted};
    `};
`

const SelectPlaceholder = styled.span`
  color: ${props => props.theme.colorMuted};
  pointer-events: none;
`

const SelectIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 0.75rem;
  display: block;
  line-height: 1;
  transform: translate3d(0, -50%, 0);
`

const SelectToggle = styled.button`
  ${TransitionPrimary('background-color, color, opacity, border-color')}
  color: ${props => props.theme.colorGlobal};
  background-color: ${props => props.theme.colorBody};
  height: ${props => props.theme.inputHeight};
  border-radius: ${props => props.theme.borderRadius};
  padding: 0 2rem 0 ${props => props.theme.inputPadding};
  border: 1px solid ${props => props.theme.colorLine};
  width: 100%;
  position: relative;
  appearance: none;
  text-align: left;

  ${props =>
    props.label &&
    css`
      padding-top: 0.7rem;
    `};

  &::label {
    color: ${props => props.theme.colorMuted};
  }

  &:focus {
    border-color: ${props => props.theme.colorPrimary};
    outline: none;
    box-shadow: none;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${props =>
    props.error &&
    css`
      color: ${props => props.theme.colorRed};
      border-color: ${props => props.theme.colorRed};
      background-color: ${props => props.theme.colorRedExtraTransparent};

      &:focus {
        border-color: ${props => props.theme.colorRed};
        ${SelectLabel} {
          color: ${props => props.theme.colorRed};
        }
      }

      &::label {
        color: ${props => props.theme.colorRed};
      }
      ${SelectLabel} {
        color: ${props => props.theme.colorRed};
      }
    `}

  ${props =>
    props.success &&
    css`
      color: ${props => props.theme.colorGreen};
      border-color: ${props => props.theme.colorGreen};
      background-color: ${props => props.theme.colorGreenTransparent};
      -webkit-text-fill-color: ${props.theme.colorGreen};

      &::label {
        color: ${props => props.theme.colorGreen};
      }
    `}
`

const SelectDropdown = styled.div`
  ${TransitionPrimary('opacity, visibility, transform')}
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  background-color: ${props => props.theme.colorBody};
  border-radius: ${props => props.theme.borderRadius};
  padding: 0.5rem ${props => props.theme.inputPadding};
  border: 1px solid ${props => props.theme.colorLine};
  width: 100%;
  height: 200px;
  overflow: auto;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0.5rem);

  ${props =>
    props.show &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    `};
`

const SelectDropdownGroup = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colorLine};
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }
`

const SelectDropdownItem = styled.button`
  ${Reset('button')}
  ${TransitionPrimary('color')}
  display: block;
  width: 100%;
  text-align: left;
  padding: 0.5rem 0;
  color: ${props => props.theme.colorGlobal};

  &:hover {
    color: ${props => props.theme.colorSecondary};
  }

  ${props =>
    props.selected &&
    css`
      color: ${props => props.theme.colorSecondary};
    `};
`

const Select = forwardRef((props, ref) => {
  const { label, options, defaultValue, onChange, name, trigger, placeholder } = props
  const [showDropdown, setShowDropdown] = useState(false)
  const elRef = useRef()

  const toggleDropdown = e => {
    e.preventDefault()
    setShowDropdown(!showDropdown)
  }

  const handleChange = (e, value) => {
    elRef.current.querySelector('select').value = value
    e.preventDefault()
    setShowDropdown(false)
    trigger(name)
    return onChange ? onChange({ name, value }) : false
  }

  const handleBlur = e => {
    console.log('blur', e)
  }

  const hideDropdown = () => {
    setShowDropdown(false)
  }

  useEffect(() => {
    window.addEventListener('click', hideDropdown)

    return () => window.removeEventListener('click', hideDropdown)
  }, [])

  return (
    <SelectWrapper ref={elRef} onClick={e => e.stopPropagation()}>
      <SelectField {...props} onBlur={handleBlur} ref={ref}>
        <option value="">{label}</option>
        {options.map((option, index) => {
          if (typeof option === typeof [])
            return (
              <optgroup key={index}>
                {option.map(item => (
                  <option key={`option_${index}_${item.value}`} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </optgroup>
            )
          else
            return (
              <option key={`option_${index}`} value={option.value}>
                {option.label}
              </option>
            )
        })}
      </SelectField>
      <SelectToggle {...props} onClick={toggleDropdown}>
        {defaultValue ? flatten(options).find(o => o.value === defaultValue).label : <SelectPlaceholder>{placeholder}</SelectPlaceholder>}
        <SelectIcon>arrow_drop_down</SelectIcon>
        <SelectLabel hasValue={!!defaultValue}>{label}</SelectLabel>
      </SelectToggle>
      <SelectDropdown show={showDropdown}>
        {options.map((option, index) => {
          if (typeof option === typeof [])
            return (
              <SelectDropdownGroup key={index}>
                {option.map(item => (
                  <SelectDropdownItem key={`${index}_${item.value}`} selected={item.value === defaultValue} onClick={e => handleChange(e, item.value)}>
                    {item.label}
                  </SelectDropdownItem>
                ))}
              </SelectDropdownGroup>
            )
          else
            return (
              <SelectDropdownItem key={index} selected={option.value === defaultValue} onClick={e => handleChange(e, option.value)}>
                {option.label}
              </SelectDropdownItem>
            )
        })}
      </SelectDropdown>
    </SelectWrapper>
  )
})

export default Select
