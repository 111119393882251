import React from 'react'
import styled from 'styled-components'
import { TransitionPrimary } from '@common/styles'
import { cover } from 'polished'

const InputRadioWrapper = styled.label`
  position: relative;
  display: block;
  padding-left: 1.5rem;
  cursor: pointer;
  @media ${props => props.theme.mq.max.md} {
    padding-left: 1.75rem;
  }

  & + & {
    margin-top: 0.25rem;
  }
`

const InputRadioIndicator = styled.span`
  position: absolute;
  top: 0.2rem;
  left: 0rem;
  height: 1rem;
  width: 1rem;
  display: inline-block;
  border: 1px solid ${props => props.theme.colorLineExtraDark};
  border-radius: 50%;

  @media ${props => props.theme.mq.max.md} {
    height: 1.25rem;
    width: 1.25rem;
    top: 0.1rem;
  }

  &:after {
    ${cover('0.25rem')}
    background-color: ${props => props.theme.colorPrimary};
    content: ' ';
    border-radius: 50%;
    transform: scale(0);
    ${TransitionPrimary('transform')}
  }
`
const InputRadioInput = styled.input`
  display: none;

  &:checked {
    & + ${InputRadioIndicator} {
      &:after {
        transform: scale(1);
      }
    }
  }
`
const InputRadioLabel = styled.span``

const InputRadio = props => {
  const { id, name, selected, children, value } = props

  const onChange = event => {
    const value = event.target.value
    return props.onChange({ name, value })
  }

  return (
    <InputRadioWrapper>
      <InputRadioInput id={id} name={name} checked={value === selected} value={value} onChange={onChange} type="radio" />
      <InputRadioIndicator />
      <InputRadioLabel htmlFor={id}>{children}</InputRadioLabel>
    </InputRadioWrapper>
  )
}

export default InputRadio
