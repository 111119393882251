import { css } from 'styled-components'
import { timingFunctions } from 'polished'

export const TransitionPrimary = (property = 'all') => css`
  transition-property: ${property};
  transition-duration: 0.5s;
  transition-timing-function: ${timingFunctions('easeOutExpo')};
  will-change: ${property};
`

export const Reset = ($type = 'everything') => {
  if ($type === 'spacing') {
    return css`
      margin: 0;
      padding: 0;
    `
  }
  if ($type === 'list') {
    return css`
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 0;
      }
    `
  }
  if ($type === 'button') {
    return css`
      padding: 0;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      outline: none;
    `
  }
  if ($type === 'everything') {
    return css`
      background-color: transparent;
      border: 0;
      float: none;
      height: auto;
      list-style: none;
      margin: 0;
      padding: 0;
      position: static;
      width: auto;
    `
  }
}
