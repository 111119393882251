import { Button, InputSelector, Link, SvgIcon, Tooltip } from '@components'
import React, { useEffect, useRef, useState } from 'react'
import { VehicleWizardSelectorActions, VehicleWizardSelectorFuel, VehicleWizardSelectorItem, VehicleWizardSelectorTypes, VehicleWizardSelectorTypesItem, VehicleWizardSelectorTypesItemIcon, VehicleWizardSelectorTypesItemName } from './VehicleWizard.styles'
import { useDispatch, useSelector } from 'react-redux'

import Icon from '@material-ui/core/Icon'

const VehicleWizardSelector = props => {
  const buttonRef = useRef(null)
  const tooltipRef = useRef(null)
  const selectedVehicle = useSelector(state => state.vehicle.selectedVehicle)
  const vehicleTypes = useSelector(state => state.vehicle.vehicleTypes)
  const vehicleFuelTypes = useSelector(state => state.vehicle.vehicleFuelTypes)
  const [vehiclePrototype, setVehiclePrototype] = useState(selectedVehicle || null)
  const dispatch = useDispatch()

  const selectType = value => {
    setVehiclePrototype({ ...vehiclePrototype, ...value })
  }

  const selectFuel = value => {
    setVehiclePrototype({ ...vehiclePrototype, fuel: value })
  }

  const handleSubmit = () => {
    dispatch({ type: 'SELECT_VEHICLE', payload: vehiclePrototype })
    dispatch({ type: 'HIDE_VEHICLE_WIZARD' })
  }

  const changeView = () => {
    dispatch({ type: 'SET_VEHICLE_WIZARD_MODE', payload: 'PLATE' })
  }

  useEffect(() => {
    if (!vehiclePrototype || !vehiclePrototype.fuel) {
      setVehiclePrototype({ ...vehiclePrototype, fuel: vehicleFuelTypes[0].value })
    }
    if (!vehiclePrototype || !vehiclePrototype.type) {
      setVehiclePrototype({ ...vehiclePrototype, ...vehicleTypes[0] })
    }
  }, [vehiclePrototype, vehicleFuelTypes, vehicleTypes])

  return (
    <React.Fragment>
      <Tooltip ref={tooltipRef}>Millainen ajoneuvo on kyseessä?</Tooltip>
      <VehicleWizardSelectorTypes>
        {vehicleTypes.map((item, index) => (
          <VehicleWizardSelectorTypesItem onClick={() => selectType(item)} active={vehiclePrototype && vehiclePrototype.type === item.type} key={`vehicle_type_${item.type}`}>
            <VehicleWizardSelectorTypesItemIcon small={index === vehicleTypes.length - 1}>
              <SvgIcon name={item.icon} />
            </VehicleWizardSelectorTypesItemIcon>
            <VehicleWizardSelectorTypesItemName>{item.name}</VehicleWizardSelectorTypesItemName>
          </VehicleWizardSelectorTypesItem>
        ))}
      </VehicleWizardSelectorTypes>
      <VehicleWizardSelectorFuel>
        {vehicleFuelTypes.map(item => (
          <VehicleWizardSelectorItem key={`vehicle_fuel_type_${item.value}`}>
            <InputSelector disabled={!vehiclePrototype || !vehiclePrototype.type} id={`vehicle_fuel_type_${item.value}`} value={item.value} selected={vehiclePrototype ? vehiclePrototype.fuel : null} onChange={() => selectFuel(item.value)}>
              {item.name}
            </InputSelector>
          </VehicleWizardSelectorItem>
        ))}
      </VehicleWizardSelectorFuel>
      <VehicleWizardSelectorActions>
        <Button VehicleWizardSelectorActions ref={buttonRef} shape="oval" onClick={handleSubmit} disabled={!vehiclePrototype || !vehiclePrototype.type || !vehiclePrototype.fuel}>
          <span>Jatka ajanvaraukseen</span>
          <Icon>arrow_forward</Icon>
        </Button>
        <Link onClick={changeView}>…tai syötärekisteritunnus</Link>
      </VehicleWizardSelectorActions>
    </React.Fragment>
  )
}

export default VehicleWizardSelector
