import styled, { css } from 'styled-components'

import { InputPlate } from '@components'
import { TransitionPrimary } from '@common/styles'

export const VehicleWizardView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10vh 0;

  > * {
    opacity: 0;
    transition: 0.5s opacity;
  }
`

export const VehicleWizardWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(4px);
  background-color: ${props => props.theme.colorBackdrop};
  z-index: 50;
  transition: all 0.5s;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  ${props =>
    props.show &&
    css`
      /* opacity: 1; */
      /* visibility: visible; */

      ${VehicleWizardView} {
        > * {
          opacity: 1;
          &:nth-child(1) {
            transition-delay: 0.15s;
          }
          &:nth-child(2) {
            transition-delay: 0.3s;
          }
          &:nth-child(3) {
            transition-delay: 0.45s;
          }
          &:nth-child(4) {
            transition-delay: 0.6s;
          }
        }
      }
    `}

  @media ${props => props.theme.mq.max.md} {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
`

export const VehicleWizardClose = styled.button`
  ${TransitionPrimary('background-color, color')}
  position: fixed;
  top: 1.5rem;
  right: 2rem;
  height: 2.1rem;
  width: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colorBody};
  border-radius: 50%;
  color: ${props => props.theme.colorPrimary};

  &:hover {
    background-color: ${props => props.theme.colorPrimary};
    color: ${props => props.theme.colorBody};
  }
`

/* Plate */

export const VehicleWizardPlateInput = styled(InputPlate)``

export const VehicleWizardPlateActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > * {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`

/* Selector */

export const VehicleWizardSelectorTypes = styled.div`
  display: flex;
  margin-bottom: 2rem;
  max-width: 100%;
  padding: 0 1rem;

  @media ${props => props.theme.mq.max.md} {
    flex-wrap: wrap;
    padding: 0 0.45rem;
  }
`
export const VehicleWizardSelectorTypesItem = styled.button`
  ${TransitionPrimary('opacity, border-color')}
  margin: 0 0.5rem;
  background-color: ${props => props.theme.colorBody};
  border-radius: ${props => props.theme.borderRadius};
  color: ${props => props.theme.colorTertiary};
  overflow: hidden;
  text-align: center;
  padding: 0.75rem 0.5rem;
  width: 11rem;
  min-width: 8rem;
  opacity: 0.5;
  border: 1px solid transparent;

  @media ${props => props.theme.mq.max.md} {
    margin: 0 0.35rem;
    flex: 0 0 calc(50% - 0.7rem);
    margin-bottom: 0.7rem;
  }

  ${props =>
    props.active &&
    css`
      pointer-events: none;
      opacity: 1;
      border-color: ${props => props.theme.colorTertiary};
    `}

  &:hover {
    opacity: 0.75;
  }
`
export const VehicleWizardSelectorTypesItemIcon = styled.span`
  margin-bottom: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.8rem;
  width: 3rem;

  svg {
    fill: ${props => props.theme.colorTertiary};
    height: 100%;
    width: 100%;
    display: block;
  }

  ${props =>
    props.small &&
    css`
      svg {
        fill: ${props => props.theme.colorTertiary};
        height: 50%;
        width: 50%;
        display: block;
      }
    `};
`
export const VehicleWizardSelectorTypesItemName = styled.h5`
  margin-bottom: 0;
`

export const VehicleWizardSelectorActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > * {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`

export const VehicleWizardSelectorFuel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  padding: 0 1rem;

  > * {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    &:last-child {
      border-right: 1rem solid transparent;
    }
  }
`

export const VehicleWizardSelectorItem = styled.div`
  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  &:last-child {
    border-right: 1rem solid transparent;
  }
`
