import styled, { css } from 'styled-components'

import { TransitionPrimary } from '@common/styles'

export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

export const CalendarMonth = styled.h5`
  text-align: center;
  font-weight: ${props => props.theme.fontPrimaryMedium};
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-top: 0;

  @media ${props => props.theme.mq.min.md} {
    padding-top: 1.5rem;
  }
  @media ${props => props.theme.mq.max.md} {
    padding-top: 1rem;
  }
`

export const CalendarDaysList = styled.div`
  position: relative;
  margin-bottom: 1rem;
`

export const CalendarSwiperWrapper = styled.div`
  .swiper-container {
    padding: 0 3rem;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 1rem, rgba(0, 0, 0, 1) 3.5rem, rgba(0, 0, 0, 1) calc(100% - 3.5rem), rgba(0, 0, 0, 0));

    @media ${props => props.theme.mq.max.md} {
      padding: 0 1rem;
      mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0.5rem, rgba(0, 0, 0, 1) 1.5rem, rgba(0, 0, 0, 1) calc(100% - 1.5rem), rgba(0, 0, 0, 0));
    }
  }

  .swiper-slide {
    padding: 0 0.25rem;
  }
`

export const CalendarDayArrow = styled.button`
  position: absolute;
  top: 0;
  width: auto;
  font-size: 0.7rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 2;

  ${props =>
    !props.visible &&
    css`
      visibility: hidden;
      opacity: 0;
    `}

  &:first-child {
    left: 1.5rem;

    &:hover {
      .material-icons {
        transform: translate3d(-0.25rem, 0, 0);
      }
    }
  }
  &:last-child {
    right: 1rem;

    &:hover {
      .material-icons {
        transform: translate3d(0.25rem, 0, 0);
      }
    }
  }

  .material-icons {
    ${TransitionPrimary('transform')}
    font-size: 1rem;
  }
  @media ${props => props.theme.mq.max.md} {
    display: none;
  }
`

export const CalendarDayWrapper = styled.button`
  ${TransitionPrimary('box-shadow, background-color, color')}
  text-align: center;
  padding: 0.5rem 0;
  line-height: 1.2;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.colorBody};
  border: 1px solid ${props => props.theme.colorLine};
  text-align: center;
  display: block;
  width: 100%;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  ${props =>
    props.active &&
    css`
      background-color: ${props => props.theme.colorPrimary};
      color: white;
    `}
`

export const CalendarDayName = styled.div`
  font-size: 0.8rem;
`

export const CalendarDayNumber = styled.div`
  font-size: 1.1rem;
  font-weight: ${props => props.theme.fontPrimaryMedium};
`

export const CalendarSlotsList = styled.div`
  flex-direction: 1 1 auto;
  overflow: auto;
  margin-top: -1rem;
  padding-top: 1rem;
  -webkit-overflow-scrolling: touch;

  @media ${props => props.theme.mq.min.md} {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media ${props => props.theme.mq.max.md} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 1rem, rgba(0, 0, 0, 1));
`

export const CalendarSlotWrapper = styled.button`
  ${TransitionPrimary('box-shadow, background-color, color')}
  background-color: ${props => (props.active ? props.theme.colorPrimary : props.theme.colorBody)};
  color: ${props => (props.active ? 'white' : 'inherit')};
  border: 1px solid ${props => props.theme.colorLine};
  height: 3.5rem;
  display: flex;
  align-items: center;
  padding: 0 1rem 0 1.5rem;
  border-radius: ${props => props.theme.borderRadius};
  width: 100%;
  margin-bottom: 0.5rem;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  @media ${props => props.theme.mq.min.md} {
    &:hover {
      ${props => (!props.active ? `box-shadow: ${props.theme.shadow}` : '')}
    }
  }
`

export const CalendarSlotPrice = styled.div`
  font-weight: ${props => props.theme.fontPrimaryBold};
  margin-left: auto;
`

export const CalendarSlotTime = styled.div`
  font-weight: ${props => props.theme.fontPrimaryMedium};
`

export const CalendarEmpty = styled.div`
  padding: 10vh 2rem;
  font-size: 1.1rem;
  text-align: center;
  color: ${props => props.theme.colorMuted};
`
