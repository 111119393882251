import React from 'react'
import styled from 'styled-components'

const FormGroupWrapper = styled.div`
  margin-bottom: 1rem;
`

const FormGroup = props => {
  return <FormGroupWrapper>{props.children}</FormGroupWrapper>
}

export default FormGroup
