import { BarDesktop, BarMobile, BarWrapper, ButtonPrimary, ButtonSecondary } from './ActionBar.styles'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@components/Button'
import React from 'react'

const ActionBar = props => {
  const selectedStation = useSelector(state => state.booking.selectedStation)
  const selectedProducts = useSelector(state => state.booking.selectedProducts)
  const selectedSlot = useSelector(state => state.booking.selectedSlot)
  const currentStep = useSelector(state => state.common.currentStep)
  const dispatch = useDispatch()

  const prevStep = () => {
    dispatch({ type: 'PREV_STEP' })
  }

  const nextStep = () => {
    if (currentStep < 3) dispatch({ type: 'NEXT_STEP' })
    else dispatch({ type: 'SHOW_CHECKOUT' })
  }

  const handleSubmit = () => {
    dispatch({ type: 'SHOW_CHECKOUT' })
  }

  return (
    <BarWrapper>
      <BarDesktop>
        {/* <SupportInfo>
          <Icon>call</Icon>Voit myös varata ajan soittamalla <strong>09 123 3456</strong>
        </SupportInfo> */}
        <Button disabled={!selectedSlot || !selectedStation || selectedProducts.length === 0} onClick={handleSubmit}>
          Varaa ja maksa
        </Button>
      </BarDesktop>
      <BarMobile>
        {currentStep > 1 && (
          <ButtonSecondary block onClick={prevStep} variant="link">
            Takaisin
          </ButtonSecondary>
        )}
        <ButtonPrimary block onClick={nextStep} disabled={(currentStep === 1 && !selectedStation) || (currentStep === 2 && selectedProducts.length === 0) || (currentStep === 3 && !selectedSlot)}>
          Seuraava
        </ButtonPrimary>
      </BarMobile>
    </BarWrapper>
  )
}

export default ActionBar
