import bookingReducer from './bookingReducer'
import { combineReducers } from 'redux'
import commonReducer from './commonReducer'
import vehicleReducer from './vehicleReducer'

const rootReducer = combineReducers({
  booking: bookingReducer,
  common: commonReducer,
  vehicle: vehicleReducer
})

export default rootReducer
