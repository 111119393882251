import immer from 'immer'
import { createTransform } from 'redux-persist'

const defaultState = {
  checkout: {
    termsAccepted: false,
    uid: null
  },
  checkoutStatus: 'DEFAULT',
  discount: null,
  dicountCode: null,
  discountValue: 0,
  discountStatus: 'DEFAULT',
  selectedStation: null,
  selectedProduct: null,
  selectedProducts: [],
  selectedSlot: null,
  selectedDay: null,
  selectedDayIndex: 0,
  taxRate: 0.24,
  pendingReservationId: null,
  payment: null,
  paymentMethods: [],
  stations: [],
  stationsStatus: 'DEFAULT',
  calendar: null,
  calendarStatus: 'DEFAULT',
  products: null,
  productsStatus: 'DEFAULT',
  status: 'DEFAULT'
}

export const bookingTransform = createTransform(
  (inboundState, key) => {
    return { ...inboundState }
  },
  (outboundState, key) => {
    if (outboundState.checkoutStatus === 'PAYMENT_PENDING') {
      return { ...outboundState }
    } else {
      return { ...defaultState }
    }
  },
  { whitelist: ['booking'] }
)

export default (state = defaultState, action) => {
  switch (action.type) {
    /**
     * Stations
     */
    case 'SELECT_STATION':
      return {
        ...state,
        selectedStation: action.payload,
        selectedProducts: [],
        selectedSlot: null
      }
    case 'SET_STATIONS_STATUS':
      return {
        ...state,
        stationsStatus: action.payload
      }
    case 'RECEIVE_STATIONS':
      // Filter out "Suomen Katsastusmies Oy"
      const filteredStation = action.payload.filter(o => o.id !== 83227)
      return {
        ...state,
        stations: filteredStation
      }
    case 'TOGGLE_PRODUCT':
      const product = action.payload
      const updatedProducts = immer(state.selectedProducts, draftState => {
        const index = state.selectedProducts.findIndex(o => o.id === product.id)
        if (index === -1) {
          return [...draftState, action.payload]
        } else {
          draftState.splice(index, 1)
          return
        }
      })
      return {
        ...state,
        selectedProducts: updatedProducts
      }
    case 'SELECT_PRODUCT':
      return {
        ...state,
        selectedSlot: null,
        selectedDayIndex: 0,
        selectedProducts: [action.payload],
        discount: null,
        dicountCode: null,
        discountValue: 0,
        discountStatus: 'DEFAULT'
      }
    case 'SET_PRODUCTS_STATUS':
      return {
        ...state,
        productsStatus: action.payload
      }
    case 'RECEIVE_PRODUCTS':
      return {
        ...state,
        products: action.payload
      }
    case 'SET_PAYMENT_METHODS':
      return {
        ...state,
        paymentMethods: action.payload
      }

    /**
     * Calendar
     */
    case 'RECEIVE_CALENDAR':
      return {
        ...state,
        calendar: action.payload,
        selectedDay: action.payload.days[0]
      }
    case 'SET_CALENDAR_STATUS':
      return {
        ...state,
        calendarStatus: action.payload
      }
    case 'SELECT_SLOT':
      return {
        ...state,
        selectedSlot: action.payload,
        discount: null,
        dicountCode: null,
        discountValue: 0,
        discountStatus: 'DEFAULT'
      }
    case 'SELECT_DAY':
      return {
        ...state,
        selectedSlot: null,
        selectedDay: action.payload.day,
        selectedDayIndex: action.payload.index,
        discount: null,
        dicountCode: null,
        discountValue: 0,
        discountStatus: 'DEFAULT'
      }
    case 'CLEAR_CALENDAR_SLOT':
      return {
        ...state,
        selectedSlot: null,
        selectedDay: null,
        selectedDayIndex: 0
      }

    /**
     * Checkout
     */
    case 'UPDATE_CHECKOUT':
      return {
        ...state,
        checkout: {
          ...state.checkout,
          [action.payload.key]: action.payload.value
        }
      }
    case 'SET_CHECKOUT_STATUS':
      return {
        ...state,
        checkoutStatus: action.payload
      }

    /**
     * Discount
     */
    case 'APPLY_DISCOUNT':
      const slot = { ...state.selectedSlot }
      const products = immer(state.selectedProducts, draftState => {
        action.payload.forEach(item => {
          const product = draftState.find(o => o.categories[0].products[0].id === item.productId)
          if (product) {
            product.priceAfterDiscount = item.priceAfterDiscount
            if (eval(product.metadata)) slot.priceAfterDiscount = item.priceAfterDiscount
          }
          return
        })
      })

      return {
        ...state,
        discount: action.payload,
        selectedSlot: slot,
        selectedProducts: products
      }
    case 'SET_DISCOUNT_STATUS':
      return {
        ...state,
        discountStatus: action.payload
      }
    case 'SET_DISCOUNT_CODE':
      return {
        ...state,
        discountCode: action.payload
      }
    case 'RESET_DISCOUNT':
      return {
        ...state,
        discount: null,
        dicountCode: null,
        discountValue: 0,
        discountStatus: 'DEFAULT'
      }

    /**
     * RESERVATION
     */
    case 'SET_PENDING_RESERVATION':
      return {
        ...state,
        pendingReservationId: action.payload
      }

    /**
     * General
     */
    case 'UPDATE_STATUS':
      return {
        ...state,
        status: action.payload
      }
    case 'RESET_BOOKING':
      return {
        ...state,
        ...defaultState
      }
    case 'SET_SETTINGS':
      return {
        ...state,
        settings: action.payload
      }
    case 'SET_PAYMENT':
      return {
        ...state,
        payment: action.payload
      }

    default:
      return state
  }
}
