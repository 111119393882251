import { ReactComponent as Logo } from '@assets/logo.svg'
import { TransitionPrimary } from '@common/styles'
import styled from 'styled-components'

export const HeaderWrapper = styled.header`
  font-size: 1em;
  display: flex;
  align-items: center;
  height: ${props => props.theme.headerHeight};
  min-height: ${props => props.theme.headerHeight};
  padding: ${props => props.theme.headerPadding};
  border-bottom: 1px solid ${props => props.theme.colorLine};
  background-color: ${props => props.theme.colorBody};

  @media ${props => props.theme.mq.max.md} {
    display: none;
  }
`

export const HeaderLogo = styled.a``

export const HeaderLogoSvg = styled(Logo)`
  height: 42px;
  display: block;
`

export const HeaderVehicle = styled.div`
  background-color: ${props => props.theme.colorLight};
  border-radius: ${props => props.theme.borderRadius};
  border: 1px solid ${props => props.theme.colorLine};
  padding: 0.5rem 0.75rem;
  margin-left: auto;
  height: ${props => props.theme.headerVehicleHeight};
  min-height: ${props => props.theme.headerVehicleHeight};
  flex: 0 0 ${props => props.theme.headerVehicleHeight};
  display: flex;
  align-items: center;
  white-space: nowrap;
`
export const HeaderVehicleIcon = styled.span`
  margin-right: 0.75rem;
  svg {
    fill: ${props => props.theme.colorTertiary};
    height: 1.75rem;
    width: 2.25rem;
    display: block;
  }
`
export const HeaderVehicleName = styled.h5`
  flex: 1 1 auto;
  margin: 0;
`
export const HeaderVehicleButton = styled.button`
  ${TransitionPrimary('background-color')}
  height: 1.65rem;
  width: 1.65rem;
  background-color: ${props => props.theme.colorMutedDark};
  border-radius: 50%;
  font-size: 0.8rem;
  color: ${props => props.theme.colorBody};
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiIcon-root {
    font-size: 1.1em;
  }

  &:hover {
    background-color: ${props => props.theme.colorPrimary};
  }
`
