import * as dayjs from 'dayjs'

import { ContentContainer, ContentEmptyState, ContentLoader, ContentStep, ContentWrapper } from './Content.styles'
import React, { useEffect } from 'react'
import { fetchCalendar, fetchProducts, fetchStations, resetBooking, resetDiscount, selectProduct, selectStation } from '@redux/actions/bookingActions'

import Calendar from '../Calendar'
import { ListItem } from '@components'
import { connect } from 'react-redux'
import { goToStep } from '@redux/actions/commonActions'
import { useWindowWidth } from '@react-hook/window-size'

const Content = props => {
  const { currentStep, stations, stationsStatus, productsStatus, products, calendarStatus, calendar, selectedStation, selectedProducts, goToStep, selectStation, selectProduct, fetchCalendar, fetchProducts, fetchStations, resetBooking } = props
  const windowWidth = useWindowWidth()

  const handleSelectStation = station => {
    selectStation(station)
    fetchProducts(new Date())
    if (windowWidth > props.theme.breakpointMd) {
      goToStep(2)
    }
  }

  const handleSelectProduct = product => {
    selectProduct(product)
    if (windowWidth > props.theme.breakpointMd) {
      goToStep(3)
    }
  }

  const initCalendar = () => {
    const startDate = dayjs()
    const endDate = dayjs().add(1, 'month')

    fetchCalendar(startDate, endDate)
  }

  useEffect(() => {
    fetchStations()
    resetBooking()
  }, [fetchStations, resetBooking])

  useEffect(() => {
    if (currentStep === 1 && !selectedStation) fetchStations()
    if (currentStep === 3 && selectedProducts.length) initCalendar()
  }, [currentStep])

  return (
    <ContentWrapper>
      <ContentContainer currentStep={currentStep}>
        <ContentStep>
          {stations && stations.map((station, index) => <ListItem type="button" title={station.name} caption={`${station.streetAddress ? `${station.streetAddress}, ` : ''} ${station.postalCode || ''} ${station.city || ''}`} onClick={() => handleSelectStation(station)} key={`station_${index}`} active={selectedStation && selectedStation.id === station.id} />)}
          {stationsStatus === 'LOADING' && <ContentLoader size={32} />}
        </ContentStep>
        <ContentStep>
          {products &&
            products
              .filter(o => o.metadata !== false)
              .map((product, index) => {
                const filteredProduct = product.categories[0].products[0]
                return <ListItem type="button" title={product.name} caption={`Alkaen ${filteredProduct.price}€`} key={`product_${index}`} onClick={() => handleSelectProduct(product)} active={selectedProducts.find(o => o.id === product.id)} showCheckbox={true} />
              })}
          {productsStatus === 'LOADING' && <ContentLoader size={32} />}
          {products && products.length === 0 && <ContentEmptyState>Ei palveluita...</ContentEmptyState>}
        </ContentStep>
        <ContentStep innerScroll={true}>
          {currentStep === 3 && calendar && selectedProducts.length > 0 && <Calendar theme={props.theme} />}
          {calendarStatus === 'LOADING' && <ContentLoader size={32} />}
        </ContentStep>
      </ContentContainer>
    </ContentWrapper>
  )
}

const mapStateToProps = state => ({
  currentStep: state.common.currentStep,
  stations: state.booking.stations,
  stationsStatus: state.booking.stationsStatus,
  selectedStation: state.booking.selectedStation,
  productsStatus: state.booking.productsStatus,
  products: state.booking.products,
  calendarStatus: state.booking.calendarStatus,
  calendar: state.booking.calendar,
  selectedProducts: state.booking.selectedProducts
})

const mapDispatchToProps = dispatch => ({
  goToStep: payload => dispatch(goToStep(payload)),
  selectStation: payload => dispatch(selectStation(payload)),
  selectProduct: payload => dispatch(selectProduct(payload)),
  fetchCalendar: (startDate, endDate) => dispatch(fetchCalendar(startDate, endDate)),
  fetchProducts: payload => dispatch(fetchProducts(payload)),
  resetBooking: payload => dispatch(resetBooking()),
  resetDiscount: payload => dispatch(resetDiscount()),
  fetchStations: () => dispatch(fetchStations())
})

export default connect(mapStateToProps, mapDispatchToProps)(Content)
