import { useDispatch, useSelector } from 'react-redux'

import { Button } from '@components'
import React from 'react'
import history from '@/lib/history'
import styled from 'styled-components'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ErrorWrapper = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: center;
`

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10vh 0;
  max-width: 600px;
  margin: 0 1rem;
  text-align: center;
`

const ErrorTitle = styled.h1`
  font-size: 1.75rem;
  margin-top: 0;
  font-weight: ${props => props.theme.fontPrimaryMedium};
  color: ${props => props.theme.colorRed};

  .material-icons {
    margin-right: 0.5rem;
    font-size: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.1em;
  }
`

const ErrorDescription = styled.p`
  font-size: 1.25rem;
`

const Error = props => {
  const checkout = useSelector(state => state.booking.checkout)
  const dispatch = useDispatch()

  const reset = () => {
    dispatch({ type: 'RESET_BOOKING' })
    dispatch({ type: 'TO_STEP', payload: 1 })
    history.replace('/')
  }

  useEffect(() => {
    console.log(checkout.uid)
    if (!checkout.uid) {
      reset()
    }
  })

  return (
    <ErrorWrapper>
      <ErrorContainer>
        <ErrorTitle>Varaus epäonnistui</ErrorTitle>
        <ErrorDescription>Valitettavasti jokin meni pieleen, kokeile uudestaan.</ErrorDescription>
        <Button onClick={reset}>Kokeile uudestaan</Button>
      </ErrorContainer>
    </ErrorWrapper>
  )
}

export default Error
