import { Button, Link, Tooltip } from '@components'
import React, { useRef, useState } from 'react'
import { VehicleWizardPlateActions, VehicleWizardPlateInput } from './VehicleWizard.styles'
import { useDispatch, useSelector } from 'react-redux'

import API from '@/api'
import Icon from '@material-ui/core/Icon'

const VehicleWizardPlate = props => {
  const buttonRef = useRef(null)
  const tooltipRef = useRef(null)
  const vehicleTypes = useSelector(state => state.vehicle.vehicleTypes)
  const [status, setStatus] = useState('DEFAULT')
  const [plateNumber, setPlateNumber] = useState(null)
  const dispatch = useDispatch()

  const handlePlateChange = value => {
    setPlateNumber(value)
  }

  const handleSubmit = () => {
    if (status !== 'SUCCESS' && plateNumber && plateNumber.length === 7) {
      setStatus('LOADING')
      API.getVehicle(plateNumber)
        .then(({ data }) => {
          buttonRef.current.updateButton(
            <React.Fragment>
              <span>Jatka ajanvaraukseen</span>
              <Icon>arrow_forward</Icon>
            </React.Fragment>
          )
          tooltipRef.current.updateTooltip(
            <React.Fragment>
              <Icon>check</Icon>
              {data.brand} {data.model}
            </React.Fragment>
          )
          setStatus('SUCCESS')
        })
        .catch(() => {
          tooltipRef.current.updateTooltip(
            <React.Fragment>
              <Icon>close</Icon>Rekisteritunnusella ei löydy tietoja
            </React.Fragment>
          )
          setStatus('ERROR')
        })
    } else if (status === 'SUCCESS') {
      dispatch({
        type: 'SELECT_VEHICLE',
        payload: {
          plateNumber: plateNumber,
          image: '/vehicle-large@3x.png',
          engine: '1.9 TDI 110KW',
          fuel: 'Diesel',
          productionYear: 'vm. 2011',
          type: 'm1',
          icon: vehicleTypes.find(o => o.type === 'm1').icon,
          name: vehicleTypes.find(o => o.type === 'm1').name,
          description: 'Punainen'
        }
      })
      dispatch({ type: 'HIDE_VEHICLE_WIZARD' })
    } else if (!plateNumber || plateNumber.length < 7) {
      tooltipRef.current.updateTooltip(
        <React.Fragment>
          <Icon>close</Icon>Rekisteritunnusella ei löydy tietoja
        </React.Fragment>
      )
      setStatus('ERROR')
    }
  }

  const changeView = () => {
    dispatch({ type: 'SET_VEHICLE_WIZARD_MODE', payload: 'SELECTOR' })
  }

  return (
    <React.Fragment>
      <Tooltip ref={tooltipRef}>Aloita syöttämällä rekisterinumerosi</Tooltip>
      <VehicleWizardPlateInput size="large" onChange={handlePlateChange} error={status === 'ERROR'} disabled={status === 'LOADING' || status === 'SUCCESS'} onSubmit={handleSubmit} />
      <VehicleWizardPlateActions>
        <Button ref={buttonRef} hasLoader isLoading={status === 'LOADING'} shape="oval" onClick={handleSubmit}>
          <Icon>search</Icon>
          <span>Hae ajoneuvon tiedot</span>
        </Button>
        <Link onClick={changeView}>…tai määrittele ajoneuvontiedot</Link>
      </VehicleWizardPlateActions>
    </React.Fragment>
  )
}

export default VehicleWizardPlate
