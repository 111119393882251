import styled, { css } from 'styled-components'

import { Loader } from '@components'
import { timingFunctions } from 'polished'
import { useWindowWidth } from '@react-hook/window-size'

export const ContentWrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  z-index: 1;
`

export const ContentContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;

  @media ${props => props.theme.mq.min.md} {
    height: calc(100vh - ${props => props.theme.headerHeight});
    height: calc((var(--vh, 1vh) * 100) - ${props => props.theme.headerHeight});
  }

  @media ${props => props.theme.mq.max.md} {
    height: 100vh;
    height: calc((var(--vh, 1vh) * 100));
    width: 300vw;
    transition: transform 1s ${timingFunctions('easeOutExpo')};
    transform: translate3d(-${props => (props.currentStep - 1) * useWindowWidth()}px, 0, 0);
  }
`

export const ContentStep = styled.div`
  position: relative;
  @media ${props => props.theme.mq.min.md} {
    flex: 0 0 33.333333%;
    padding: ${props => (props.innerScroll ? '0' : '1.5rem')};
    height: calc(100vh - ${props => props.theme.headerHeight} - ${props => props.theme.actionBarHeight} - ${props => props.theme.stepsHeight});
    height: calc((var(--vh, 1vh) * 100) - ${props => props.theme.headerHeight} - ${props => props.theme.actionBarHeight} - ${props => props.theme.stepsHeight});
  }
  @media ${props => props.theme.mq.max.md} {
    width: 100vw;
    min-width: 100vw;
    padding: 1rem;
    padding: ${props => (props.innerScroll ? '0' : '1rem')};
    height: calc(100vh - ${props => props.theme.actionBarHeight});
    height: calc((var(--vh, 1vh) * 100) - ${props => props.theme.actionBarHeight} - ${props => props.theme.stepsHeight});
  }
  ${props =>
    !props.innerScroll &&
    css`
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    `};
  ${props =>
    props.innerScroll &&
    css`
      overflow: hidden;
    `};

  &:not(:last-child) {
    border-right: 1px solid ${props => props.theme.colorLineDark};
  }
`

export const ContentLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`

export const ContentEmptyState = styled.div`
  padding: 10vh 2rem;
  font-size: 1.1rem;
  text-align: center;
  color: ${props => props.theme.colorMuted};
`
