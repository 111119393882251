import React from 'react'
import styled from 'styled-components'
import { TransitionPrimary } from '@common/styles'
import Icon from '@material-ui/core/Icon'
import InputCheckbox from '@components/InputCheckbox'

const ListItemWrapper = styled.div`
  position: relative;
  border-radius: ${props => props.theme.borderRadius};
  padding: 1rem 1.25rem;
  text-align: left;
  background-color: ${props => (props.active ? props.theme.colorPrimary : props.theme.colorBody)};
  color: ${props => (props.active ? 'white' : 'inherit')};
  border: 1px solid ${props => props.theme.colorLine};
  display: flex;
  min-height: 4.75rem;
  align-items: ${props => (props.caption ? 'flex-start' : 'center')};
  width: 100%;
  line-height: 1.15;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const ListItemButton = styled(ListItemWrapper)`
  ${TransitionPrimary('background-color, color, box-shadow')}
  &:hover {
    ${props => (!props.active ? `box-shadow: ${props.theme.shadow}` : '')}
  }
`

const ListItemIcon = styled(Icon)`
  margin-top: ${props => (props.caption ? '0.45rem' : 0)};
  margin-right: 1.25rem;
  flex: 0 0 1.5rem;
  font-size: 1rem;
`

const ListItemContent = styled.div`
  margin-right: 0.5rem;
  flex: 1 1 auto;
`

const ListItemTitle = styled.h3`
  font-size: 1rem;
  font-weight: ${props => props.theme.fontPrimaryMedium};
  margin-bottom: ${props => (props.caption ? '0.25rem' : '0')};
  margin-top: 0;
`

const ListItemCaption = styled.span`
  font-size: 1rem;
  opacity: 0.5;
`

const ListItemCheckbox = styled(InputCheckbox)`
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;
  @media ${props => props.theme.mq.max.md} {
    right: 0.25rem;
  }
`

const ListItem = props => {
  const Component = props.type === 'button' ? ListItemButton : ListItemWrapper
  const isSelected = props.active

  return (
    <Component as={props.type === 'button' ? 'button' : 'div'} caption={props.caption} active={isSelected} onClick={props.onClick}>
      {props.icon && <ListItemIcon caption={props.caption}>{props.icon}</ListItemIcon>}
      <ListItemContent>
        <ListItemTitle caption={props.caption}>{props.title}</ListItemTitle>
        <ListItemCaption>{props.caption}</ListItemCaption>
      </ListItemContent>
      {props.showCheckbox && <ListItemCheckbox inverted={isSelected} selected={isSelected} preventClick />}
    </Component>
  )
}

export default ListItem
