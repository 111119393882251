import Button from '@components/Button'
import styled from 'styled-components'

export const BarWrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.colorBody};
  z-index: 4;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
`

export const Bar = styled.div`
  display: flex;
  align-items: center;
  height: ${props => props.theme.actionBarHeight};
  padding: ${props => props.theme.actionBarPadding};
`

export const BarDesktop = styled(Bar)`
  justify-content: flex-end;

  @media ${props => props.theme.mq.max.md} {
    display: none;
  }
`

export const BarMobile = styled(Bar)`
  @media ${props => props.theme.mq.min.md} {
    display: none;
  }
`

export const SupportInfo = styled.div`
  margin-right: 1.5rem;

  .material-icons {
    margin-right: 0.5rem;
    font-size: 1rem;
    vertical-align: middle;
  }
`

export const ButtonSecondary = styled(Button)`
  font-size: 1.2rem;
  border-radius: ${props => props.theme.borderRadiusMd};
`
export const ButtonPrimary = styled(Button)`
  font-size: 1.2rem;
  border-radius: ${props => props.theme.borderRadiusMd};
  font-weight: ${props => props.theme.fontPrimaryMedium};
  flex: 0 0 60%;
  margin-left: auto;
`
