import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { ReactComponent as InputPlateIconSvg } from '@assets/FIN.svg'

const InputPlateWrapper = styled.div`
  display: flex;

  ${props =>
    props.size === 'large' &&
    css`
      margin-bottom: 2rem;
      font-size: 1rem;

      @media ${props => props.theme.mq.min.xs} {
        width: 28.5em;
        max-width: 28.5em;
        height: 7em;
      }

      @media ${props => props.theme.mq.max.xs} {
        width: calc(100% - 2em);
        max-width: calc(100% - 2em);
        height: 5em;
      }
    `};

  ${props =>
    props.size === 'default' &&
    css`
      margin-bottom: 1rem;
      font-size: 0.52rem;
      height: 5.8em;

      @media ${props => props.theme.mq.min.xs} {
        width: 31em;
        max-width: 31em;
      }

      @media ${props => props.theme.mq.max.xs} {
        width: 25em;
        max-width: 25em;
      }
    `};
`

const InputPlateIcon = styled.div`
  background-color: ${props => props.theme.colorPlate};
  width: 4em;
  flex: 0 0 4em;
  border-top-left-radius: ${props => props.theme.borderRadius};
  border-bottom-left-radius: ${props => props.theme.borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.size === 'default' &&
    css`
      border-top-left-radius: 0.22rem;
      border-bottom-left-radius: 0.22rem;
    `};

  @media ${props => props.theme.mq.max.xs} {
    width: 3em;
    flex: 0 0 3em;
  }

  svg {
    width: 55%;
    height: 70%;
  }
`

const InputPlateControl = styled.div`
  height: 100%;
  background-color: ${props => props.theme.colorBody};
  flex: 1 1 auto;
  border-top-right-radius: ${props => props.theme.borderRadius};
  border-bottom-right-radius: ${props => props.theme.borderRadius};
  font-size: 3.9em;
  align-items: center;
  font-weight: ${props => props.theme.fontPrimaryMedium};
  display: block;
  transition: 0.2s ease-out;
  display: flex;
  text-align: center;
  color: ${props => props.theme.colorPlatePlaceholder};
  border: 1px solid ${props => props.theme.colorLine};
  overflow: hidden;

  @media ${props => props.theme.mq.max.xs} {
    font-size: 2.75em;
  }

  ${props =>
    props.size === 'default' &&
    css`
      border-top-right-radius: 0.22rem;
      border-bottom-right-radius: 0.22rem;
    `};

  ${props =>
    props.error &&
    css`
      color: ${props => props.theme.colorRedTransparent};
    `}

  ${props =>
    props.active &&
    css`
      color: ${props => (props.error ? props.theme.colorRed : props.theme.colorBlack)};
    `}

  > span {
    display: block;
  }
`

const InputPlateControlField = styled.input`
  font-size: inherit;
  box-shadow: none;
  border: 0;
  text-transform: uppercase;
  transition: 0.2s ease-out;
  font-weight: ${props => props.theme.fontPrimaryMedium};
  flex: 1 0 0;
  width: calc(50% - 0.25em);
  max-width: calc(50% - 0.25em);
  text-align: center;
  letter-spacing: 0.1em;
  padding: 0 0.1em;
  background-color: transparent;
  color: ${props => props.theme.colorBlack};
  appearance: none;

  &::placeholder {
    color: ${props => props.theme.colorPlatePlaceholder};
    transition: 0.2s ease-out;
    border: 0;
    background: transparent;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  ${props =>
    props.error &&
    css`
      color: ${props => props.theme.colorRed};
      &::placeholder {
        color: ${props => props.theme.colorRedTransparent};
      }
    `}

  ${props => props.disabled && css``}
`

const InputPlate = React.forwardRef((props, ref) => {
  const inputOneRef = useRef(null)
  const inputTwoRef = useRef(null)
  const [plateNumber, setPlateNumber] = useState(typeof props.value === typeof [] ? props.value.split('-') : new Array(2))
  const [parsedPlateNumber, setParsedPlateNumber] = useState(props.value || '')

  useEffect(() => {
    setPlateNumber(props.value ? props.value.split('-') : null)
    setParsedPlateNumber(props.value || '')
  }, [props.value])

  const onChange = (e, index) => {
    const platePrototype = plateNumber || new Array(2)
    platePrototype[index] = e.target.value
    setPlateNumber(platePrototype)
    setParsedPlateNumber(platePrototype.join('-'))

    return props.onChange ? props.onChange(platePrototype.join('-')) : false
  }

  const onSubmit = () => {
    return props.onSubmit ? props.onSubmit() : false
  }

  const handleKey = e => {
    const code = e.keyCode || e.charCode

    switch (code) {
      case 8:
        if (parsedPlateNumber.length === 4) {
          inputOneRef.current.focus()
        }
        break
      case 13:
        onSubmit()
        break
      default:
        if (parsedPlateNumber.length === 4) {
          inputTwoRef.current.focus()
        }
        return
    }
  }

  return (
    <InputPlateWrapper size={props.size}>
      <InputPlateIcon size={props.size}>
        <InputPlateIconSvg />
      </InputPlateIcon>
      <InputPlateControl error={props.error} active={parsedPlateNumber.length >= 4} size={props.size}>
        <InputPlateControlField name={props.name} disabled={props.disabled} ref={inputOneRef} error={props.error} placeholder="ABC" maxLength={3} onChange={e => onChange(e, 0)} onKeyUp={e => handleKey(e)} value={plateNumber ? plateNumber[0] : ''} />
        <span>-</span>
        <InputPlateControlField name={props.name} disabled={props.disabled} ref={inputTwoRef} error={props.error} placeholder="123" maxLength={3} onChange={e => onChange(e, 1)} onKeyUp={e => handleKey(e)} value={plateNumber ? plateNumber[1] : ''} />
      </InputPlateControl>
    </InputPlateWrapper>
  )
})

InputPlate.defaultProps = {
  size: 'default'
}

export default InputPlate
