const defaultState = {
  totalSteps: 3,
  currentStep: 1,
  showCheckout: false,
  showVehicleWizard: false,
  vehicleWizardMode: 'SELECTOR',
  steps: [
    {
      name: 'Valitse toimipiste',
      icon: 'store'
    },
    {
      name: 'Valitse palvelu',
      icon: 'assignment_turned_in'
    },
    {
      name: 'Valitse aika',
      icon: 'event'
    }
  ]
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PREV_STEP':
      return {
        ...state,
        currentStep: state.currentStep - 1
      }
    case 'NEXT_STEP':
      return {
        ...state,
        currentStep: state.currentStep + 1
      }
    case 'TO_STEP':
      return {
        ...state,
        currentStep: action.payload
      }
    case 'SHOW_CHECKOUT':
      return {
        ...state,
        showCheckout: true
      }
    case 'HIDE_CHECKOUT':
      return {
        ...state,
        showCheckout: false
      }
    case 'SHOW_VEHICLE_WIZARD':
      return {
        ...state,
        showVehicleWizard: true
      }
    case 'HIDE_VEHICLE_WIZARD':
      return {
        ...state,
        showVehicleWizard: false
      }
    case 'SET_VEHICLE_WIZARD_MODE':
      return {
        ...state,
        vehicleWizardMode: action.payload
      }
    default:
      return state
  }
}
