import React from 'react'
import styled, { css } from 'styled-components'
import InputCheckbox from './InputCheckbox'
import { TransitionPrimary } from '@common/styles'

const InputSelectorWrapper = styled.button`
  ${TransitionPrimary('background-color, color, opacity')}
  position: relative;
  display: inline-flex;
  cursor: pointer;
  background-color: ${props => props.theme.colorBody};
  white-space: nowrap;
  align-items: center;
  height: ${props => props.theme.buttonHeight};
  padding: 0 1.5rem;
  border-radius: 5rem;

  ${props =>
    props.selected &&
    css`
      background-color: ${props => props.theme.colorPrimary};
      color: ${props => props.theme.colorBody};
    `}

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

const InputSelectorInput = styled.input`
  display: none;
`
const InputSelectorLabel = styled.span`
  padding-left: 0.5rem;
`

const InputSelector = props => {
  const { id, name, selected, children, value, disabled } = props
  const isSelected = value === selected

  const onChange = event => {
    const value = event.target.checked
    return props.onChange ? props.onChange({ name, value }) : false
  }

  return (
    <InputSelectorWrapper selected={isSelected} disabled={disabled} onClick={onChange}>
      <InputSelectorInput id={id} name={name} checked={isSelected} onChange={onChange} value={value} type="radio" />
      <InputCheckbox inverted={isSelected} selected={isSelected} preventClick />
      <InputSelectorLabel htmlFor={id}>{children}</InputSelectorLabel>
    </InputSelectorWrapper>
  )
}

export default InputSelector
