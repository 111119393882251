import styled, { css } from 'styled-components'

import Icon from '@material-ui/core/Icon'
import React from 'react'

const AlertIcon = styled(Icon)`
  font-size: 1.2em;
  flex: 0 0 2rem;
`

const AlertContent = styled.div``

const AlertWrapper = styled.label`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: ${props => props.theme.borderRadius};
  font-weight: ${props => props.theme.fontPrimaryMedium};
  ${props =>
    props.variant === 'success' &&
    css`
      background-color: ${props => props.theme.colorGreenTransparent};
      color: ${props => props.theme.colorGreen};
    `}
`

const Alert = props => {
  const { variant } = props
  return (
    <AlertWrapper variant={variant}>
      <AlertIcon>check</AlertIcon>
      <AlertContent>{props.children}</AlertContent>
    </AlertWrapper>
  )
}

export default Alert
