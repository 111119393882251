import { FormGroup, InputCheckbox, InputPlate, InputRadio, InputText, Label, Link, Select } from '@components'
import { Col, Row, setConfiguration } from 'react-grid-system'
import React, { useMemo } from 'react'
import { submitDiscountCode, toggleProduct, updateCheckout } from '@/redux/actions/bookingActions'

import { CheckoutFields } from './Checkout.styles'
import { PAYMENT_NAMES } from '@common/helpers'
import { connect } from 'react-redux'
import { hideCheckout } from '@/redux/actions/commonActions'
import { setVehicle } from '@/redux/actions/vehicleActions'
import { useFormContext } from 'react-hook-form'

setConfiguration({ gutterWidth: 8 })

const CheckoutForm = props => {
  const { selectedProducts, toggleProduct, products, selectedVehicle, checkout, discountStatus, setVehicle, updateCheckout, hideCheckout, submitDiscountCode, discountCode, paymentMethods } = props
  const { register, formState, errors, trigger } = useFormContext()
  const { isSubmitted } = formState
  const otherProducts = useMemo(() => products && products.filter(o => o.metadata === false), [products])
  const paymentMethodsComputed = useMemo(() => {
    const methods = paymentMethods.map(item => item.paymentMethods)
    return methods.map(group => group.map(item => ({ label: PAYMENT_NAMES[item], value: item })))
  }, [paymentMethods])

  const handleUpdateCheckout = payload => {
    updateCheckout({ key: payload.name, value: payload.value })
  }

  const handlePlateChange = value => {
    setVehicle({
      plateNumber: value
    })
  }

  const handleSelectProduct = product => {
    if (discountStatus === 'SUCCESS') {
      submitDiscountCode(discountCode)
    }
    toggleProduct(product)
  }

  return (
    <CheckoutFields>
      {/* Close */}
      <Link prependIcon="arrow_back" onClick={hideCheckout}>
        Muokkaa valintoja
      </Link>
      {/* Fields */}
      <Label>Yhteystiedot</Label>
      <FormGroup>
        <Row>
          <Col xs={6}>
            <InputText name="firstName" defaultValue={checkout.firstName} ref={register({ required: true, minLength: 3 })} label="Etunimi" error={errors.hasOwnProperty('firstName')} onBlur={handleUpdateCheckout} />
          </Col>
          <Col xs={6}>
            <InputText name="lastName" defaultValue={checkout.lastName} ref={register({ required: true, minLength: 3 })} label="Sukunimi" error={errors.hasOwnProperty('lastName')} onBlur={handleUpdateCheckout} />
          </Col>
        </Row>
        <InputText
          name="email"
          defaultValue={checkout.email}
          ref={register({
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
            }
          })}
          label="Sähköposti"
          error={errors.hasOwnProperty('email')}
          onBlur={handleUpdateCheckout}
        />
        <InputText name="phone" defaultValue={checkout.phone} ref={register({ required: true, minLength: 3 })} label="Puhelinnumero" error={errors.hasOwnProperty('phone')} onBlur={handleUpdateCheckout} />
        <Label>Rekisteritunnus</Label>
        {selectedVehicle && <InputPlate value={selectedVehicle.plateNumber || ''} error={isSubmitted && (!selectedVehicle.plateNumber || selectedVehicle.plateNumber.length < 2)} onChange={handlePlateChange} />}
      </FormGroup>
      {/* Additional Test */}
      {selectedProducts.lengh > 0 && selectedProducts[0].categories.length > 1 && (
        <>
          <Label>Päästötodistus</Label>
          <FormGroup>
            <InputRadio id="radioA1" name="radioA" value="radioA1" onChange={handleUpdateCheckout} selected={checkout.radioA}>
              Minulla on alle 3kk vanha päästötodistus
            </InputRadio>
            <InputRadio id="radioA2" name="radioA" value="radioA2" onChange={handleUpdateCheckout} selected={checkout.radioA}>
              Minulla ei ole päästötodistusta
            </InputRadio>
          </FormGroup>
        </>
      )}
      {/* Other Products */}
      {otherProducts && otherProducts.length > 0 && (
        <>
          <Label>Muut</Label>
          <FormGroup>
            {otherProducts.map((product, index) => {
              const filteredProduct = product.categories[0].products[0]
              return (
                <InputCheckbox key={`extra_product_${product.id}`} id={product.id} name="other" value={product.id} onChange={() => handleSelectProduct(product)} selected={selectedProducts.find(o => o.id === product.id)}>
                  {product.name} (+{`${filteredProduct.price}€`})
                </InputCheckbox>
              )
            })}
          </FormGroup>
        </>
      )}

      {/* Payment */}
      {paymentMethodsComputed.length > 0 && (
        <FormGroup>
          <Label>Maskutapa *</Label>
          <Select name="paymentMethod" defaultValue={checkout.paymentMethod} ref={register({ required: true, minLength: 3 })} placeholder="Valitse maskutapa" error={errors.hasOwnProperty('paymentMethod')} options={paymentMethodsComputed} onChange={handleUpdateCheckout} trigger={trigger}></Select>
        </FormGroup>
      )}
    </CheckoutFields>
  )
}

const mapStateToProps = state => ({
  selectedProducts: state.booking.selectedProducts,
  selectedVehicle: state.vehicle.selectedVehicle,
  checkout: state.booking.checkout,
  discountValue: state.booking.discountValue,
  discountStatus: state.booking.discountStatus,
  discountCode: state.booking.discountCode,
  paymentMethods: state.booking.paymentMethods,
  products: state.booking.products
})

const mapDispatchToProps = dispatch => ({
  submitDiscountCode: payload => dispatch(submitDiscountCode(payload)),
  setVehicle: payload => dispatch(setVehicle(payload)),
  updateCheckout: payload => dispatch(updateCheckout(payload)),
  toggleProduct: payload => dispatch(toggleProduct(payload)),
  hideCheckout: () => dispatch(hideCheckout())
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm)
