import { CSSTransition, SwitchTransition } from 'react-transition-group'
import React, { useEffect, useState } from 'react'
import { VehicleWizardClose, VehicleWizardView, VehicleWizardWrapper } from './VehicleWizard.styles'
import { useDispatch, useSelector } from 'react-redux'

import Icon from '@material-ui/core/Icon'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import VehicleWizardPlate from './VehicleWizardPlate'
import VehicleWizardSelector from './VehicleWizardSelector'

let allowToClose = false

const VehicleWizard = props => {
  const showVehicleWizard = useSelector(state => state.common.showVehicleWizard)
  const vehicleWizardMode = useSelector(state => state.common.vehicleWizardMode)
  const selectedVehicle = useSelector(state => state.vehicle.selectedVehicle)
  const [isEntering, setIsEntering] = useState(false)
  const nodeRef = React.useRef(null)
  const nodeRef2 = React.useRef(null)
  const dispatch = useDispatch()

  const cancel = () => {
    if (selectedVehicle) {
      dispatch({ type: 'HIDE_VEHICLE_WIZARD' })
    }
  }

  useEffect(() => {
    allowToClose = selectedVehicle ? true : false
  }, [selectedVehicle])

  return (
    <CSSTransition nodeRef={nodeRef} in={showVehicleWizard} timeout={500} classNames="fade-in" unmountOnExit onEntering={() => setIsEntering(true)} onExited={() => setIsEntering(false)}>
      <VehicleWizardWrapper ref={nodeRef} show={isEntering}>
        <SwitchTransition mode="out-in" nodeRef={nodeRef2}>
          <CSSTransition key={vehicleWizardMode} timeout={300} classNames="fade-in" nodeRef={nodeRef2}>
            <VehicleWizardView ref={nodeRef2}>{vehicleWizardMode === 'PLATE' ? <VehicleWizardPlate /> : <VehicleWizardSelector />}</VehicleWizardView>
          </CSSTransition>
        </SwitchTransition>
        <KeyboardEventHandler handleFocusableElements handleKeys={['esc']} onKeyEvent={(key, e) => cancel()} />
        {allowToClose && (
          <VehicleWizardClose onClick={cancel}>
            <Icon>close</Icon>
          </VehicleWizardClose>
        )}
      </VehicleWizardWrapper>
    </CSSTransition>
  )
}

export default VehicleWizard
