import Icon from '@material-ui/core/Icon'
import styled from 'styled-components'
import { timingFunctions } from 'polished'

export const StepsWrapper = styled.div`
  position: relative;
`

export const StepsList = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colorBody};
  height: ${props => props.theme.stepsHeight};
  min-height: ${props => props.theme.stepsHeight};
`

export const StepsListItem = styled.div`
  flex: 1 0 0;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  opacity: ${props => (props.active || props.completed ? 1 : 0.5)};
  transition: opacity 0.3s ease-out;
  will-change: opacity;
`

export const StepsListItemName = styled.h5`
  margin-left: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: ${props => props.theme.fontPrimaryMedium};

  @media ${props => props.theme.mq.max.sm} {
    display: none;
  }
`
export const StepsListItemIcon = styled(Icon)``

export const StepsProgress = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: ${props => props.progress}%;
  background-color: ${props => props.theme.colorSecondary};
  transition: width 1s ${timingFunctions('easeOutExpo')};
  will-change: width;
`
