import * as dayjs from 'dayjs'

import { Alert, Modal } from '@/components'
import { Button, FormGroup, InputCheckbox, InputGroup, InputText, Label, ListItem } from '@components'
import { CheckoutDetailTitle, CheckoutDetailValue, CheckoutDetailWrapper, CheckoutSummaryBottom, CheckoutSummaryWrapper } from './Checkout.styles'
import React, { useMemo, useState } from 'react'
import { fetchSettings, submitDiscountCode, updateCheckout, updateDiscountCode } from '@/redux/actions/bookingActions'
import { getTotalAmount, getTotalDiscount } from '@/redux/selectors/bookingSelector'

import HTMLReactParser from 'html-react-parser'
import { connect } from 'react-redux'
import { setConfiguration } from 'react-grid-system'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

setConfiguration({ gutterWidth: 8 })

const CheckoutSummary = props => {
  const { selectedStation, selectedProducts, selectedSlot, selectedVehicle, checkout, discountStatus, updateCheckout, submitDiscountCode, getTotalAmount, getTotalDiscount, taxRate, discountCode, updateDiscountCode, settings, fetchSettings } = props
  const discountButtonRef = React.useRef(null)
  const [showTerms, setShowTerms] = useState(false)

  const { formState } = useFormContext()
  const { isValid, isSubmitted } = formState

  useEffect(() => {
    fetchSettings()
  }, [fetchSettings])

  const CheckoutDetail = ({ isSummary, title, value, color }) => {
    return (
      <CheckoutDetailWrapper color={color} isSummary={isSummary}>
        <CheckoutDetailTitle isSummary={isSummary}>{title}</CheckoutDetailTitle>
        <CheckoutDetailValue isSummary={isSummary}>{value}</CheckoutDetailValue>
      </CheckoutDetailWrapper>
    )
  }

  const handleUpdateCheckout = payload => {
    updateCheckout({ key: payload.name, value: payload.value })
  }

  const handleDiscountCodeChange = payload => {
    updateDiscountCode(payload.value)
  }

  const handleSubmitDiscountCode = () => {
    submitDiscountCode(discountCode).catch(() => {
      discountButtonRef.current.updateButton('Try again...')
    })
  }

  const handleModalClose = () => {
    setShowTerms(false)
  }

  const readyToSubmit = useMemo(() => {
    return isValid && checkout.termsAccepted && selectedVehicle && selectedVehicle.plateNumber && selectedVehicle.plateNumber.length > 1
  }, [isValid, checkout.termsAccepted, selectedVehicle])

  if (selectedStation && selectedSlot && selectedProducts.length > 0) {
    return (
      <CheckoutSummaryWrapper>
        {/* Details */}
        <Label>Varauksen tiedot</Label>
        <div>
          <ListItem title={selectedStation.name} caption={selectedStation.city} icon="store" />
          {selectedProducts.map(product => (
            <ListItem title={product.name} key={`product_${product.id}`} caption={product.description} icon="assignment_turned_in" />
          ))}
          <ListItem title={dayjs(selectedSlot.time).format('DD.M.YYYY HH:mm')} icon="event" />
        </div>
        <Label>Onko sinulla alennuskuponki?</Label>

        {/* Discount - Input */}
        <InputGroup marginBottom={discountStatus === 'SUCCESS' ? 'small' : 'default'}>
          <InputText id="discount" placeholder="Kuponkikoodi" error={discountStatus === 'ERROR'} disabled={discountStatus === 'SUCCESS'} onChange={handleDiscountCodeChange} />
          <Button ref={discountButtonRef} size="sm" onClick={handleSubmitDiscountCode} disabled={discountStatus === 'SUCCESS'} hasLoader isLoading={discountStatus === 'LOADING'}>
            Käytä
          </Button>
        </InputGroup>

        {/* Discount - Alert */}
        {discountStatus === 'SUCCESS' && <Alert variant="success">Coupon Accepted!</Alert>}

        {/* Details */}
        <CheckoutDetail title={selectedProducts[0].name} value={`${selectedSlot.price}€`} />
        {selectedProducts
          .filter(o => o.metadata === false)
          .map(product => {
            const filteredProduct = product.categories[0].products[0]
            return <CheckoutDetail key={`detail_extra_${product.id}`} title={product.name} value={`${filteredProduct.priceAfterDiscount || filteredProduct.price}€`} />
          })}
        <CheckoutDetail title="ALV (24%)" value={`${(getTotalAmount.toFixed(2) * taxRate).toFixed(2)}€`} />
        {discountStatus === 'SUCCESS' && <CheckoutDetail title="Verkkoalennus" value={`-${getTotalDiscount.toFixed(2)}€`} color="colorGreen" />}
        <CheckoutDetail title="Kaikki yhteensä" value={`${getTotalAmount.toFixed(2)}€`} isSummary={true} />

        {/* Bottom */}
        <CheckoutSummaryBottom>
          <FormGroup>
            <InputCheckbox id="termsAccepted" name="termsAccepted" value="checkboxA1" onChange={handleUpdateCheckout} selected={checkout.termsAccepted} error={isSubmitted && !checkout.termsAccepted}>
              Olen lukenut ja hyväksyn{' '}
              <button type="button" onClick={() => setShowTerms(true)}>
                varausehdot
              </button>
            </InputCheckbox>
          </FormGroup>
          <Button type="submit" block={true} noDynamicChanges unactive={!readyToSubmit}>
            Varaa ja maksa {`${getTotalAmount.toFixed(2)}€`}
          </Button>
        </CheckoutSummaryBottom>

        {/* Terms */}
        <Modal title="Terms" show={showTerms} onClose={handleModalClose}>
          {settings ? HTMLReactParser(settings?.termsOfService) : ''}
        </Modal>
      </CheckoutSummaryWrapper>
    )
  } else {
    return <div />
  }
}

const mapStateToProps = state => ({
  selectedStation: state.booking.selectedStation,
  selectedProducts: state.booking.selectedProducts,
  selectedSlot: state.booking.selectedSlot,
  selectedVehicle: state.vehicle.selectedVehicle,
  checkout: state.booking.checkout,
  discountStatus: state.booking.discountStatus,
  discountCode: state.booking.discountCode,
  taxRate: state.booking.taxRate,
  settings: state.booking.settings,
  getTotalAmount: getTotalAmount(state),
  getTotalDiscount: getTotalDiscount(state)
})

const mapDispatchToProps = dispatch => ({
  submitDiscountCode: payload => dispatch(submitDiscountCode(payload)),
  updateDiscountCode: payload => dispatch(updateDiscountCode(payload)),
  updateCheckout: payload => dispatch(updateCheckout(payload)),
  fetchSettings: payload => dispatch(fetchSettings(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSummary)
