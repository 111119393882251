import styled, { css } from 'styled-components'

import { InputTextWrapper } from './InputText.js'
import React from 'react'

const InputGroupWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;

  ${props =>
    props.marginBottom === 'small' &&
    css`
      margin-bottom: 0.5rem;
    `};

  ${InputTextWrapper} {
    margin-bottom: 0;
  }

  > * {
    &:first-child,
    &:first-child > input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      margin-left: 0;
      border-right: none;
    }
    &:last-child,
    &:last-child > input {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-left: none;
    }
  }
`

const InputGroup = props => {
  const { marginBottom } = props
  return <InputGroupWrapper marginBottom={marginBottom}>{props.children}</InputGroupWrapper>
}

export default InputGroup
