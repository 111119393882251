import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import Icon from '@material-ui/core/Icon'
import Loader from '@components/Loader'
import { TransitionPrimary } from '@common/styles'
import { animate } from 'popmotion'

export const ButtonWrapper = styled.button`
  ${TransitionPrimary('background-color, color, opacity')}
  position: relative;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  width: ${props => (props.block ? '100%' : 'auto')};
  min-width: 5rem;
  height: ${props => props.theme.buttonHeight};
  border-radius: ${props => props.theme.borderRadius};
  padding: 0 ${props => props.theme.buttonPadding};
  font-weight: ${props => props.theme.fontPrimaryBold};

  .MuiIcon-root {
    vertical-align: middle;
    font-size: 1.1em;

    &:first-child {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }
  }

  ${props =>
    props.size === 'sm' &&
    css`
      font-size: 0.9rem;
      font-weight: ${props => props.theme.fontPrimaryMedium};
    `}

  ${props =>
    props.size === 'lg' &&
    css`
      font-size: 1.1rem;
      font-weight: ${props => props.theme.fontPrimaryMedium};
      box-shadow: ${props => props.theme.shadowPrimary};
    `}

  ${props =>
    props.shape === 'oval' &&
    css`
      font-size: 1.1rem;
      border-radius: 3rem;
      padding: 0 ${props => props.theme.buttonOvalPadding};
      font-weight: ${props => props.theme.fontPrimaryRegular};
    `}

  ${props =>
    props.isLoading &&
    css`
      pointer-events: none;
    `}

  ${props =>
    props.variant === 'primary' &&
    css`
      color: white;
      background-color: ${props => props.theme.colorPrimary};

      ${props =>
        !props.disabled &&
        !props.unactive &&
        !props.success &&
        css`
          &:hover {
            background-color: ${props => props.theme.colorSecondary};
            color: ${props => props.theme.colorPrimary};
          }
        `}

      ${props =>
        props.isLoading &&
        css`
          background-color: ${props => props.theme.colorSecondary};
          color: ${props => props.theme.colorPrimary};
        `}

      ${props =>
        props.disabled &&
        css`
          opacity: 0.75;
          cursor: not-allowed;
          background-color: ${props => props.theme.colorLightTransparent};
          color: ${props => props.theme.colorMuted};
        `}

      ${props =>
        props.unactive &&
        css`
          opacity: 0.75;
          cursor: not-allowed;
          background-color: ${props => props.theme.colorLightTransparent};
          color: ${props => props.theme.colorMuted};
        `}
    `}

  ${props =>
    props.variant === 'link' &&
    css`
      color: inherit;
      font-weight: ${props => props.theme.fontPrimaryRegular};

      &:hover {
        color: inherit;
      }
    `}

  ${props =>
    props.success &&
    css`
      color: ${props => props.theme.colorGreen};
      border-color: ${props => props.theme.colorGreen};
      background-color: ${props => props.theme.colorGreenTransparent};
      opacity: 0.5;
      pointer-events: none;
    `}
`
export const ButtonInner = styled.span`
  display: block;
`
export const ButtonText = styled.span`
  ${TransitionPrimary('visibility, opacity, transform')}
  white-space: nowrap;
  ${props =>
    props.isLoading &&
    css`
      visibility: hidden;
      opacity: 0;
      transform: translate3d(0, -0.25rem, 0);
    `}
`
export const ButtonIcon = styled(Icon)`
  margin-left: ${props => (props.append ? '0.5rem' : '0')};
  margin-right: ${props => (props.prepend ? '0.5rem' : '0')};
  vertical-align: middle;
  &.MuiIcon-root {
    font-size: 1.1em;
  }
`
export const ButtonLoader = styled(Loader)`
  ${TransitionPrimary('visibility, opacity, transform')}
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);

  ${props =>
    !props.isLoading &&
    css`
      visibility: hidden;
      opacity: 0;
      transform: translate3d(0, -0.25rem, 0);
    `}
  ${props =>
    props.isLoading &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    `}
`

const Button = forwardRef((props, ref) => {
  const [title, setTitle] = useState(props.children)
  const inner = useRef()
  const text = useRef()
  const initialChildren = props.children

  useEffect(() => {
    inner.current.style.width = `${inner.current.clientWidth}px`
  }, [])

  useImperativeHandle(ref, () => ({
    updateButton(title) {
      animate({
        from: 1,
        to: 0,
        duration: 300,
        onUpdate: val => (inner.current.style.opacity = val),
        onComplete: () => {
          setTitle(title)
          animate({
            from: 0,
            to: 1,
            elapsed: -300,
            duration: 300,
            onUpdate: val => (inner.current.style.opacity = val)
          })
          animate({
            from: inner.current.clientWidth,
            to: text.current.getBoundingClientRect().width,
            duration: 300,
            onUpdate: val => (inner.current.style.width = `${val}px`)
          })
        }
      })
    },
    reset() {
      animate({
        from: 1,
        to: 0,
        duration: 300,
        onUpdate: val => (inner.current.style.opacity = val),
        onComplete: () => {
          setTitle(initialChildren)
          animate({
            from: 0,
            to: 1,
            elapsed: -300,
            duration: 300,
            onUpdate: val => (inner.current.style.opacity = val)
          })
          animate({
            from: inner.current.clientWidth,
            to: text.current.getBoundingClientRect().width,
            duration: 300,
            onUpdate: val => (inner.current.style.width = `${val}px`)
          })
        }
      })
    }
  }))

  const ButtonBase = () => (
    <Fragment>
      <ButtonInner ref={inner}>
        <ButtonText ref={text} isLoading={props.isLoading}>
          {props.prependIcon && <ButtonIcon prepend={props.prependIcon}>{props.prependIcon}</ButtonIcon>}
          {props.noDynamicChanges ? props.children : title}
          {props.appendIcon && <ButtonIcon append={props.appendIcon}>{props.appendIcon}</ButtonIcon>}
        </ButtonText>
      </ButtonInner>
      {props.hasLoader && <ButtonLoader isLoading={props.isLoading} size={24} />}
    </Fragment>
  )

  return props.href ? (
    <ButtonWrapper {...props} as="a" href={props.href} target={props.target || '_self'}>
      <ButtonBase />
    </ButtonWrapper>
  ) : (
    <ButtonWrapper {...props}>
      <ButtonBase />
    </ButtonWrapper>
  )
})

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  success: false,
  size: 'default',
  unactive: false,
  hasLoader: false,
  isLoading: false,
  shape: 'rounded'
}

export default Button
