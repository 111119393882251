import React from 'react'
import styled from 'styled-components'
import { timingFunctions } from 'polished'
import Icon from '@material-ui/core/Icon'

const LinkWrapper = styled.a`
  position: relative;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;

  > span:not(.material-icons) {
    position: relative;

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      bottom: 0;
      background-color: currentColor;
      content: ' ';
      transition: transform 1.2s ${timingFunctions('easeOutExpo')};
      transform-origin: left center;
      height: 1px;
    }
  }

  &:hover {
    &:not(:disabled):not(.disabled) {
      > span:not(.material-icons):after {
        transform-origin: right center;
        transform: scaleX(0);
      }
    }
  }
`

const LinkIcon = styled(Icon)`
  margin-left: ${props => (props.append ? '0.5rem' : '0')};
  margin-right: ${props => (props.prepend ? '0.5rem' : '0')};
  vertical-align: middle;
  &.MuiIcon-root {
    font-size: 1.1em;
  }
`

const Link = props => {
  return (
    <LinkWrapper {...props}>
      {props.prependIcon && <LinkIcon prepend={props.prependIcon}>{props.prependIcon}</LinkIcon>}
      <span>{props.children}</span>
      {props.appendIcon && <LinkIcon append={props.appendIcon}>{props.appendIcon}</LinkIcon>}
    </LinkWrapper>
  )
}

export default Link
