import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { Route, Router, Switch } from 'react-router-dom'
import React, { useEffect } from 'react'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import ActionBar from './modules/ActionBar'
import Checkout from './modules/Checkout/Checkout'
import Content from './modules/Content/Content'
import Error from './Error'
import Header from './modules/Header'
import Result from './Result'
import Steps from './modules/Steps'
import VehicleWizard from './modules/VehicleWizard'

import history from '@/lib/history'
import { normalize } from 'polished'
import { resetBooking } from '@/redux/actions/bookingActions'

const theme = require(`sass-extract-loader?{"plugins":["sass-extract-js"]}!../scss/_variables.scss`)

const GlobalStyle = createGlobalStyle`${normalize()}`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colorLight};
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`

const Main = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Wizard = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetBooking())
  }, [dispatch])

  return (
    <React.Fragment>
      <Steps />
      <Content theme={theme} />
      <ActionBar />
    </React.Fragment>
  )
}

const App = () => {
  const selectedVehicle = useSelector(state => state.vehicle.selectedVehicle)
  const status = useSelector(state => state.booking.status)
  const showCheckout = useSelector(state => state.common.showCheckout)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!selectedVehicle) dispatch({ type: 'SHOW_VEHICLE_WIZARD' })
  }, [dispatch, selectedVehicle])

  return (
    <ThemeProvider theme={theme}>
      {/* Global Styles */}
      <GlobalStyle />

      {/* Wrapper */}
      <Wrapper>
        <Header />
        <SwitchTransition mode="out-in">
          <Router history={history}>
            <Main>
              <CSSTransition key={status} timeout={300} classNames="fade-in">
                <Switch>
                  <Route exact path="/">
                    <Wizard />
                  </Route>
                  <Route path="/success">
                    <Result />
                  </Route>
                  <Route path="/failed">
                    <Error />
                  </Route>
                </Switch>
              </CSSTransition>
            </Main>
          </Router>
        </SwitchTransition>
      </Wrapper>

      {/* Checkout */}
      <CSSTransition in={showCheckout} timeout={showCheckout ? 300 : 1000} classNames="fade-in" unmountOnExit mountOnEnter>
        <Checkout />
      </CSSTransition>

      {/* Vehicle Wizard */}
      <VehicleWizard />
    </ThemeProvider>
  )
}

export default App
