import API from '@/api'

export const setDiscountStatus = payload => ({
  type: 'SET_DISCOUNT_STATUS',
  payload
})

export const updateStatus = payload => ({
  type: 'UPDATE_STATUS',
  payload
})

export const updateCheckout = payload => ({
  type: 'UPDATE_CHECKOUT',
  payload
})

export const updateCheckoutStatus = payload => ({
  type: 'SET_CHECKOUT_STATUS',
  payload
})

export const selectSlot = payload => ({
  type: 'SELECT_SLOT',
  payload
})

export const selectDay = payload => ({
  type: 'SELECT_DAY',
  payload
})

export const toggleProduct = payload => ({
  type: 'TOGGLE_PRODUCT',
  payload
})

export const selectProduct = payload => ({
  type: 'SELECT_PRODUCT',
  payload
})

export const selectStation = payload => ({
  type: 'SELECT_STATION',
  payload
})

export const updateDiscountCode = payload => ({
  type: 'SET_DISCOUNT_CODE',
  payload
})

export const clearCalendarSlot = payload => ({
  type: 'CLEAR_CALENDAR_SLOT'
})

export const resetDiscount = payload => ({
  type: 'RESET_DISCOUNT'
})

export const resetBooking = () => {
  return function (dispatch) {
    dispatch({
      type: 'RESET_BOOKING'
    })
  }
}
export const fetchStations = () => {
  return function (dispatch) {
    dispatch({
      type: 'SET_STATIONS_STATUS',
      payload: 'LOADING'
    })

    return API.getStations().then(response => {
      dispatch({
        type: 'RECEIVE_STATIONS',
        payload: response.data
      })
      dispatch({
        type: 'SET_STATIONS_STATUS',
        payload: 'SUCCESS'
      })
    })
  }
}

export const prepareCheckout = () => {
  return function (dispatch, getState) {
    const state = getState()
    const booking = state.booking
    const vehicle = state.vehicle

    dispatch({
      type: 'SET_CHECKOUT_STATUS',
      payload: 'LOADING'
    })

    return API.getPaymentMethods(booking.selectedStation.id).then(response => {
      dispatch({
        type: 'SET_PAYMENT_METHODS',
        payload: response.data
      })

      return API.createPendingReservation({
        plateNumber: vehicle.selectedVehicle.plateNumber,
        time: booking.selectedSlot.time,
        vehicleCategory: vehicle.selectedVehicle.type,
        stationId: booking.selectedStation.id,
        productIds: booking.selectedProducts.map(o => o.id)
      }).then(response => {
        dispatch({
          type: 'SET_PENDING_RESERVATION',
          payload: response.data
        })
        dispatch({
          type: 'SET_CHECKOUT_STATUS',
          payload: 'DEFAULT'
        })
      })
    })
  }
}

export const createPayment = () => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      const state = getState()
      const booking = state.booking
      const checkout = state.booking.checkout
      const vehicle = state.vehicle

      dispatch({
        type: 'SET_CHECKOUT_STATUS',
        payload: 'LOADING'
      })

      return API.createB2CCustomer({
        firstName: checkout.firstName,
        lastName: checkout.lastName,
        email: checkout.email,
        phoneNumber: checkout.phone,
        stationId: booking.selectedStation.id,
        plateNumber: vehicle.selectedVehicle.plateNumber,
        receiveSms: true,
        receiveEmail: true
      }).then(response => {
        const customerId = response.data.uid
        dispatch({
          type: 'UPDATE_CHECKOUT',
          payload: { key: 'uid', value: customerId }
        })

        return API.createPayment({
          stationId: booking.selectedStation.id,
          plateNumber: vehicle.selectedVehicle.plateNumber,
          calendarEventId: booking.pendingReservationId,
          onlinePaymentMethod: checkout.paymentMethod === 'cash' ? null : checkout.paymentMethod,
          productIds: booking.selectedProducts.map(o => o.id),
          b2CCustomerUid: customerId,
          vehicleCategory: vehicle.selectedVehicle.type,
          campaignCode: booking.discountStatus === 'SUCCESS' ? booking.discountCode : null
        })
          .then(response => {
            dispatch({
              type: 'SET_PAYMENT',
              payload: response.data
            })
            dispatch({
              type: 'SET_CHECKOUT_STATUS',
              payload: 'PAYMENT_PENDING'
            })
            resolve(response.data)
          })
          .catch(() => {
            dispatch({
              type: 'SET_CHECKOUT_STATUS',
              payload: 'DEFAULT'
            })
            reject()
          })
      })
    })
  }
}

export const createReservation = () => {
  return function (dispatch, getState) {
    const state = getState()
    const booking = state.booking
    const checkout = state.booking.checkout
    const vehicle = state.vehicle

    dispatch({
      type: 'SET_CHECKOUT_STATUS',
      payload: 'LOADING'
    })

    return API.createReservation({
      calendarEventId: booking.pendingReservationId,
      plateNumber: vehicle.selectedVehicle.plateNumber,
      vehicleCategory: vehicle.selectedVehicle.type,
      stationId: booking.selectedStation.id,
      b2CCustomerUid: checkout.uid,
      productIds: booking.selectedProducts.map(o => o.id),
      campaignCode: booking.discountStatus === 'SUCCESS' ? booking.discountCode : null
    }).then(response => {
      console.log('createReservation', response)
      dispatch({
        type: 'SET_CHECKOUT_STATUS',
        payload: 'DEFAULT'
      })
    })
  }
}

export const fetchProducts = date => {
  return function (dispatch, getState) {
    const state = getState()

    dispatch({
      type: 'SET_PRODUCTS_STATUS',
      payload: 'LOADING'
    })

    return API.getProductBundles(state.booking.selectedStation.id, state.vehicle.selectedVehicle.type, date).then(response => {
      dispatch({
        type: 'RECEIVE_PRODUCTS',
        payload: response.data
      })
      dispatch({
        type: 'SET_PRODUCTS_STATUS',
        payload: 'SUCCESS'
      })
    })
  }
}

export const fetchSettings = date => {
  return function (dispatch, getState) {
    const state = getState()

    return API.getSettings(state.booking.selectedStation.id).then(response => {
      dispatch({
        type: 'SET_SETTINGS',
        payload: response.data
      })
    })
  }
}

export const fetchCalendar = (startDate, endDate) => {
  return function (dispatch, getState) {
    const state = getState()

    if (state.booking.selectedProducts.length > 0) {
      dispatch({
        type: 'SET_CALENDAR_STATUS',
        payload: 'LOADING'
      })
      return API.getCalendar(state.booking.selectedStation.id, state.vehicle.selectedVehicle.type, state.booking.selectedProducts[0].categories[0].products[0].id, startDate, endDate).then(response => {
        dispatch({
          type: 'RECEIVE_CALENDAR',
          payload: response.data
        })
        dispatch({
          type: 'SET_CALENDAR_STATUS',
          payload: 'SUCCESS'
        })
      })
    }
  }
}

export const submitDiscountCode = code => {
  return function (dispatch, getState) {
    const state = getState()

    dispatch({ type: 'SET_DISCOUNT_STATUS', payload: state.booking.discountStatus === 'SUCCESS' ? 'SUCCESS' : 'LOADING' })
    return new Promise((resolve, reject) => {
      return API.submitDiscountCode(code)
        .then(response => {
          if (response.data.codeExists) {
            return API.getDiscount(
              state.booking.selectedStation.id,
              state.vehicle.selectedVehicle.type,
              state.booking.selectedSlot.time,
              code,
              state.booking.selectedProducts.map(o => o.categories[0].products[0].id)
            )
              .then(response => {
                dispatch({ type: 'SET_DISCOUNT_STATUS', payload: 'SUCCESS' })
                dispatch({ type: 'APPLY_DISCOUNT', payload: response.data })
                resolve(response.data)
              })
              .catch(e => {
                dispatch({ type: 'SET_DISCOUNT_STATUS', payload: 'ERROR' })
                reject()
              })
          } else {
            dispatch({ type: 'SET_DISCOUNT_STATUS', payload: 'ERROR' })
            reject('FALSE')
          }
        })
        .catch(e => {
          dispatch({ type: 'SET_DISCOUNT_STATUS', payload: 'ERROR' })
        })
    })
  }
}
