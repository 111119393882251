import * as dayjs from 'dayjs'

import axios from 'axios'

const CHAIN_ID = 78845

const api = axios.create({
  baseURL: 'https://muster.fi/booking.api/v1'
})

export default {
  getSettings: stationId => api.get(`/Settings?stationId=${stationId}`),
  getVehicle: plateNumber => api.get(`/Vehicle?ChainId=${CHAIN_ID}&PlateNumber=${plateNumber}`),
  getStations: () => api.get(`/Stations?chainId=${CHAIN_ID}`),
  getProductBundles: (stationId, vechicleCategory, reservationDateTime) => api.get(`/ProductBundles?chainId=${CHAIN_ID}&StationId=${stationId}&VehicleCategory=${vechicleCategory}&ReservationDateTime=${dayjs(reservationDateTime).format('YYYY-MM-DD')}`),
  getCalendar: (stationId, vechicleCategory, productId, startDate, endDate) => api.get(`/Calendar/${stationId}?chainId=${CHAIN_ID}&VehicleCategory=${vechicleCategory}&StartDate=${dayjs(startDate).format('YYYY-MM-DD')}&EndDate=${dayjs(endDate).format('YYYY-MM-DD')}&ProductId=${productId}`),
  submitDiscountCode: code => api.get(`/CampaignCode/${CHAIN_ID}/GetAvailability?desiredCodeName=${code}`),
  getDiscount: (stationId, vechicleCategory, reservationDateTime, code, productIds) => api.get(`/Discounts/${stationId}/GetPriceAffected?&vehicleCategory=${vechicleCategory}&reservationDate=${reservationDateTime}&campaignCode=${code}${productIds.map(id => `&productIds=${id}`)}`),
  getPaymentMethods: stationId => api.get(`/OnlinePaymentMethods?stationId=${stationId}`),
  createPendingReservation: payload => api.post(`/PendingReservations`, payload),
  createReservation: payload => api.post(`/Reservations/modifiable`, payload),
  getReservation: id => api.get(`/Reservations?uid=${id}`),
  createPayment: payload => api.post(`/Payments`, payload),
  createB2CCustomer: payload => api.post(`/B2CCustomer`, payload)
}
