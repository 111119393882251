import { CheckoutBackdrop, CheckoutContent, CheckoutLoader, CheckoutMobileHeader, CheckoutWrapper } from './Checkout.styles'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, Loader } from '@components'
import React, { useEffect, useMemo, useState } from 'react'
import { createPayment, createReservation, prepareCheckout, updateCheckoutStatus } from '@/redux/actions/bookingActions'

import { CSSTransition } from 'react-transition-group'
import CheckoutForm from './CheckoutForm'
import CheckoutSummary from './CheckoutSummary'
import { connect } from 'react-redux'
import { hideCheckout } from '@/redux/actions/commonActions'
import history from '@/lib/history'

const Checkout = props => {
  const { selectedVehicle, checkout, checkoutStatus, hideCheckout, prepareCheckout, createPayment } = props
  const contentRef = React.useRef(null)
  const [showMobileHeader, setShowMobileHeader] = useState(false)
  const [showContent, setShowContent] = useState(false)

  const form = useForm({
    mode: 'onBlur'
  })
  const { formState, handleSubmit } = form
  const { isValid } = formState

  useEffect(() => {
    setShowMobileHeader(false)
    prepareCheckout().then(() => {
      setShowContent(true)
    })
    if (contentRef.current) contentRef.current.addEventListener('scroll', handleScroll)
  }, [prepareCheckout])

  const handleScroll = e => {
    if (e.target.scrollTop > 100) setShowMobileHeader(true)
    else setShowMobileHeader(false)
  }

  const handleSubmitCheckout = () => {
    if (readyToSubmit) {
      createPayment()
        .then(response => {
          const params = response.parameters
          let query = ''

          params.forEach((o, i) => {
            query += `${o.key}=${o.value}${i <= params.length ? '&' : ''}`
          })

          console.log(`Payment URL: ${response.url}?${query}`)

          window.location.href = `${response.url}?${query}`
        })
        .catch(error => {
          console.error('handleSubmitCheckout', error)
          hideCheckout()
          history.replace('/failed')
        })
    }
    return false
  }

  const readyToSubmit = useMemo(() => {
    return isValid && checkout.termsAccepted && selectedVehicle && selectedVehicle.plateNumber && selectedVehicle.plateNumber.length > 1
  }, [isValid, checkout.termsAccepted, selectedVehicle])

  return (
    <CheckoutWrapper show={showContent}>
      <CheckoutBackdrop onClick={hideCheckout} />
      {/* Mobile Header */}
      <CheckoutMobileHeader show={showMobileHeader}>
        <Link prependIcon="arrow_back" onClick={hideCheckout}>
          Muokkaa valintoja
        </Link>
      </CheckoutMobileHeader>
      <FormProvider {...form}>
        <CheckoutContent onSubmit={handleSubmit(handleSubmitCheckout)} ref={contentRef}>
          {/* Form */}
          <CheckoutForm />

          {/* Summary */}
          <CheckoutSummary />
        </CheckoutContent>
      </FormProvider>

      {/* Loader */}
      <CSSTransition in={checkoutStatus === 'LOADING'} timeout={300} classNames="fade-in" unmountOnExit mountOnEnter>
        <CheckoutLoader>
          <Loader />
        </CheckoutLoader>
      </CSSTransition>
    </CheckoutWrapper>
  )
}

const mapStateToProps = state => ({
  selectedVehicle: state.vehicle.selectedVehicle,
  checkout: state.booking.checkout,
  checkoutStatus: state.booking.checkoutStatus,
  payment: state.booking.payment
})

const mapDispatchToProps = dispatch => ({
  updateCheckoutStatus: payload => dispatch(updateCheckoutStatus(payload)),
  hideCheckout: () => dispatch(hideCheckout()),
  prepareCheckout: () => dispatch(prepareCheckout()),
  createPayment: () => dispatch(createPayment()),
  createReservation: () => dispatch(createReservation())
})

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
