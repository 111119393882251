import { cover, timingFunctions } from 'polished'
import styled, { css } from 'styled-components'

import { TransitionPrimary } from '@common/styles'

export const CheckoutBackdrop = styled.div`
  ${cover()}
  backdrop-filter: blur(4px);
  background-color: ${props => props.theme.colorBackdrop};
`

export const CheckoutContent = styled.form`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  box-shadow: ${props => props.theme.shadow};
  background-color: ${props => props.theme.colorBody};
  width: 100vw;
  transform: translateX(100%);
  transition: transform 3s ${timingFunctions('easeOutExpo')};

  @media ${props => props.theme.mq.min.md} {
    width: 900px;
    display: flex;
    overflow: hidden;
  }

  @media ${props => props.theme.mq.max.md} {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
`

export const CheckoutWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 10;

  ${props =>
    props.show &&
    css`
      ${CheckoutContent} {
        transition-duration: 1s;
        transform: translateX(0);
      }
    `}
  }
`

export const CheckoutFields = styled.div`
  position: relative;
  flex: 0 0 55%;
  padding: 1rem 1.5rem;

  @media ${props => props.theme.mq.min.md} {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media ${props => props.theme.mq.max.md} {
    padding: 1rem;
  }
`

export const CheckoutMobileHeader = styled.div`
  ${TransitionPrimary('visibility, transform')}
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 1rem;
  z-index: 3;
  background-color: ${props => props.theme.colorBody};
  border-bottom: 1px solid ${props => props.theme.colorLineDark};
  visibility: hidden;
  transform: translate3d(0, -100%, 0);

  ${props =>
    props.show &&
    css`
      visibility: visible;
      transform: translate3d(0, 0, 0);
    `}

  @media ${props => props.theme.mq.min.md} {
    display: none;
  }
`

export const CheckoutSummaryWrapper = styled.div`
  background-color: ${props => props.theme.colorLight};
  flex: 0 0 45%;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;

  > * {
    flex-shrink: 0;
  }

  @media ${props => props.theme.mq.min.md} {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media ${props => props.theme.mq.max.md} {
    padding: 1rem;
  }
`

export const CheckoutSummaryBottom = styled.div`
  margin-top: auto;
  @media ${props => props.theme.mq.max.md} {
    margin-top: 2rem;
  }
`

export const CheckoutDetailWrapper = styled.dl`
  margin: 0.15rem 0;
  display: flex;
  font-size: ${props => (props.isSummary ? '1.2rem' : '1rem')};
  color: ${props => (props.color ? props.theme[props.color] : 'inherit')};

  ${props =>
    props.isSummary &&
    css`
      margin: 1rem 0;
      padding: 0.75rem 0;
      border-top: 1px dotted ${props => props.theme.colorLineExtraDark};
      border-bottom: 1px dotted ${props => props.theme.colorLineExtraDark};
    `}
`

export const CheckoutDetailTitle = styled.dt`
  font-weight: ${props => (props.isSummary ? props.theme.fontPrimaryBold : props.theme.fontPrimaryMedium)};
`

export const CheckoutDetailValue = styled.dd`
  margin-left: auto;
  font-weight: ${props => (props.isSummary ? props.theme.fontPrimaryBold : props.theme.fontPrimaryMedium)};
`

export const CheckoutLoader = styled.div`
  ${cover()};
  background-color: ${props => props.theme.colorBackdrop};
  display: flex;
  align-items: center;
  justify-content: center;
`
