import React from 'react'
import styled from 'styled-components'

const LabelText = styled.label`
  font-weight: ${props => props.theme.fontPrimaryMedium};
  margin-bottom: 0.5rem;
  display: block;

  &:not(:first-child) {
    margin-top: 1rem;
  }
`

const Label = props => {
  return <LabelText>{props.children}</LabelText>
}

export default Label
