import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'

// import { createFilter } from 'redux-persist-transform-filter'
import rootReducer from './reducers/rootReducer'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunkMiddleware from 'redux-thunk'
import { bookingTransform } from './reducers/bookingReducer'

// const persitingReducers = createFilter('booking', ['selectedVehicle'])

const persistConfig = {
  key: 'unto',
  whitelist: ['vehicle', 'booking'],
  storage,
  transforms: [bookingTransform]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false
  }),
  thunkMiddleware
})

export const persistor = persistStore(store)
