const defaultState = {
  selectedVehicle: null,
  vehicleTypes: [
    {
      name: 'Henkilöauto',
      type: 'm1',
      icon: 'car'
    },
    {
      name: 'Mopot, mootoripyörät',
      type: 'l1',
      icon: 'motorcycle'
    },
    {
      name: 'Perävaunut',
      type: 'o1',
      icon: 'trailer'
    },
    {
      name: 'Raskas kalusto',
      type: 'n2',
      icon: 'crane'
    },
    {
      name: 'Traktorit',
      type: 'c1',
      icon: 'tractor'
    },
    {
      name: 'Muut',
      type: 'muu',
      icon: 'more'
    }
  ],
  vehicleFuelTypes: [
    {
      name: 'Diesel',
      value: 'Diesel'
    },
    {
      name: 'Bensiini',
      value: 'Bensiini'
    },
    {
      name: 'Sähkö',
      value: 'Sähkö'
    },
    {
      name: 'Kaasu',
      value: 'Kaasu'
    }
  ]
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SELECT_VEHICLE':
      return {
        ...state,
        selectedVehicle: action.payload
      }
    case 'SET_VEHICLE':
      return {
        ...state,
        selectedVehicle: {
          ...state.selectedVehicle,
          ...action.payload
        }
      }
    default:
      return state
  }
}
