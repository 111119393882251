import * as dayjs from 'dayjs'

import { Button, Link, ListItem } from '@components'
import { useDispatch, useSelector } from 'react-redux'

import Icon from '@material-ui/core/Icon'
import React from 'react'
import { google } from 'calendar-link'
import history from '@/lib/history'
import styled from 'styled-components'

const ResultWrapper = styled.div`
  padding: 5vh 0;
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const ResultOuter = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ResultContainer = styled.div`
  max-width: 600px;
  margin: 0 1rem;
  text-align: center;
`

const ResultInner = styled.div`
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
`

const ResultTitle = styled.h1`
  font-size: 1.75rem;
  margin-top: 0;
  font-weight: ${props => props.theme.fontPrimaryMedium};

  .material-icons {
    margin-right: 0.5rem;
    font-size: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.1em;
  }
`

const ResultDescription = styled.p`
  font-size: 1.25rem;
`

const ResultActions = styled.div`
  > * {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`

const ResultAlert = styled.div`
  margin-bottom: 1.5rem;
  background-color: ${props => props.theme.colorOrange};
  padding: 1rem;
  border-radius: ${props => props.theme.borderRadius};
  line-height: 1.35;
`

const Result = props => {
  const selectedStation = useSelector(state => state.booking.selectedStation)
  const selectedProducts = useSelector(state => state.booking.selectedProducts)
  const selectedSlot = useSelector(state => state.booking.selectedSlot)
  const dispatch = useDispatch()

  const reset = () => {
    dispatch({ type: 'RESET_BOOKING' })
    dispatch({ type: 'TO_STEP', payload: 1 })
    history.replace('/')
  }

  const addToCalendar = () => {
    const event = {
      title: 'Unto Booking',
      description: selectedStation.name,
      start: selectedSlot.time,
      duration: [30, 'minute']
    }
    return google(event)
  }

  return (
    selectedStation &&
    selectedProducts &&
    selectedSlot && (
      <ResultWrapper>
        <ResultOuter>
          <ResultContainer>
            <ResultTitle>
              <Icon>check_circle</Icon>
              Booking confirmed!
            </ResultTitle>
            <ResultDescription>
              An email with more information has been sent to <strong>hello@email.com</strong>
            </ResultDescription>
            <ResultInner>
              <ResultAlert>
                Information related to <strong>COVID-19</strong>
                <Link>Read our precautions here.</Link>
              </ResultAlert>
              {selectedStation && <ListItem title={selectedStation.name} caption={selectedStation.city} icon="store" />}
              {selectedProducts && selectedProducts.map(product => <ListItem title={product.name} key={`product_${product.id}`} caption={product.description} icon="assignment_turned_in" />)}
              {selectedSlot && <ListItem title={dayjs(selectedSlot.time).format('DD.M.YYYY HH:hh')} icon="event" />}
              <ResultActions>
                <Button href={addToCalendar()} target="_blank" size="lg" block={true} onClick={addToCalendar}>
                  Add to calendar
                </Button>
                <Link onClick={reset}>Back to Website</Link>
              </ResultActions>
            </ResultInner>
          </ResultContainer>
        </ResultOuter>
      </ResultWrapper>
    )
  )
}

export default Result
