import { HeaderLogo, HeaderLogoSvg, HeaderVehicle, HeaderVehicleButton, HeaderVehicleIcon, HeaderVehicleName, HeaderWrapper } from './Header.styles'
import { useDispatch, useSelector } from 'react-redux'

import { CSSTransition } from 'react-transition-group'
import Icon from '@material-ui/core/Icon'
import React from 'react'
import { SvgIcon } from '@components'

const Header = props => {
  const selectedVehicle = useSelector(state => state.vehicle.selectedVehicle)
  const status = useSelector(state => state.booking.status)
  const nodeRef = React.useRef(null)
  const dispatch = useDispatch()

  const showVehicleWizard = () => {
    dispatch({ type: 'SHOW_VEHICLE_WIZARD' })
  }

  return (
    <HeaderWrapper>
      <HeaderLogo href="https://unto.fi/" target="_blank">
        <HeaderLogoSvg alt="Unto" />
      </HeaderLogo>
      <CSSTransition nodeRef={nodeRef} in={selectedVehicle !== null} timeout={200} classNames="fade-in" unmountOnExit>
        <React.Fragment>
          {selectedVehicle && status === 'DEFAULT' && (
            <HeaderVehicle ref={nodeRef}>
              <HeaderVehicleIcon>
                <SvgIcon name={selectedVehicle.icon} />
              </HeaderVehicleIcon>
              {/* <HeaderVehicleName>{selectedVehicle.plateNumber ? `${selectedVehicle.plateNumber} | ${selectedVehicle.name}, ${selectedVehicle.type}` : `${selectedVehicle.type}, ${selectedVehicle.fuel}`}</HeaderVehicleName> */}
              <HeaderVehicleName>{`${selectedVehicle.name}, ${selectedVehicle.fuel}`}</HeaderVehicleName>
              <HeaderVehicleButton onClick={showVehicleWizard}>
                <Icon>edit</Icon>
              </HeaderVehicleButton>
            </HeaderVehicle>
          )}
        </React.Fragment>
      </CSSTransition>
    </HeaderWrapper>
  )
}

export default Header
