import { createSelector } from 'reselect'
import { sumBy } from 'lodash'

const getSelectedProducts = state => state.booking.selectedProducts
const getDiscount = state => state.booking.discount
const getSelectedSlot = state => state.booking.selectedSlot

export const getTotalAmount = createSelector([getSelectedProducts, getSelectedSlot], (products, slot) => {
  const extrasAmount = sumBy(products, o => (o.metadata === false ? o.categories[0].products[0].price || o.categories[0].products[0].priceAfterDiscount : 0))
  const slotAmount = slot ? slot.priceAfterDiscount || slot.price : ''
  return extrasAmount + slotAmount
})

export const getTotalDiscount = createSelector([getDiscount], discount => {
  return discount ? sumBy(discount, o => o.originalPrice - o.priceAfterDiscount) : 0
})
