import { Reset, TransitionPrimary } from '@common/styles'
import styled, { css } from 'styled-components'

import React from 'react'
import { timingFunctions } from 'polished'

const InputCheckboxWrapper = styled.label`
  position: relative;
  display: block;
  cursor: pointer;
  min-height: 1.4rem;
  min-width: 16px;
  padding-left: 1.5rem;
  @media ${props => props.theme.mq.max.md} {
    padding-left: 1.75rem;
    min-width: 20px;
  }
  pointer-events: ${props => (props.preventClick ? 'none' : 'auto')};

  & + & {
    margin-top: 0.25rem;
  }
`

const InputCheckboxIndicator = styled.span`
  position: absolute;
  top: 0.2rem;
  left: 0rem;
  height: 16px;
  width: 16px;
  display: inline-block;
  border: 1px solid ${props => (props.error ? props.theme.colorRed : props.theme.colorLineExtraDark)};
  border-radius: ${props => props.theme.borderRadiusSm};

  ${props =>
    props.inverted &&
    css`
      border: 1px solid ${props => props.theme.colorBody};
    `}

  @media ${props => props.theme.mq.max.md} {
    height: 20px;
    width: 20px;
    top: 0.1rem;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colorPrimary};
    content: ' ';
    border-radius: ${props => props.theme.borderRadiusSm};
    transform: scale(0);
    ${TransitionPrimary('transform')}

    ${props =>
      props.inverted &&
      css`
        background-color: ${props => props.theme.colorBody};
      `}
  }
`
const InputCheckboxInput = styled.input`
  display: none;

  &:checked {
    & + ${InputCheckboxIndicator} {
      &:after {
        transform: scale(0.6);
      }
    }
  }
`
const InputCheckboxLabel = styled.span`
  display: block;
  color: ${props => (props.error ? props.theme.colorRed : 'inherit')};

  a,
  button {
    ${Reset('button')}
    position: relative;
    color: inherit;
    cursor: pointer;
    text-decoration: none;

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      bottom: 0;
      background-color: currentColor;
      content: ' ';
      transition: transform 1.2s ${timingFunctions('easeOutExpo')};
      transform-origin: left center;
      height: 1px;
    }

    &:hover {
      &:not(:disabled):not(.disabled) {
        &::after {
          transform-origin: right center;
          transform: scaleX(0);
        }
      }
    }
  }
`

const InputCheckbox = React.forwardRef((props, ref) => {
  const { id, name, selected, children, value, preventClick, inverted, error } = props

  const onChange = event => {
    const value = event.target.checked
    return props.onChange ? props.onChange({ name, value }) : false
  }

  return (
    <InputCheckboxWrapper preventClick={preventClick} className={props.className}>
      <InputCheckboxInput ref={ref} id={id} name={name} checked={selected} value={value} onChange={onChange} type="checkbox" />
      <InputCheckboxIndicator inverted={inverted} error={error} />
      {children && (
        <InputCheckboxLabel htmlFor={id} error={error}>
          {children}
        </InputCheckboxLabel>
      )}
    </InputCheckboxWrapper>
  )
})

export default InputCheckbox
