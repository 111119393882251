import React from 'react'
import styled, { css } from 'styled-components'

import Icon from '@material-ui/core/Icon'
import { TransitionPrimary } from '@common/styles'

export const InputTextWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 0.5rem;
`

export const InputTextLabel = styled.span`
  ${TransitionPrimary('font-size, color, margin-top')}
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate3d(0, -50%, 0);
  padding: 0 ${props => props.theme.inputPadding};
  z-index: 2;
  color: ${props => props.theme.colorMuted};
  pointer-events: none;
`

export const InputTextControl = styled.input`
  ${TransitionPrimary('background-color, color, opacity, border-color')}
  color: ${props => props.theme.colorGlobal};
  background-color: ${props => props.theme.colorBody};
  height: ${props => props.theme.inputHeight};
  border-radius: ${props => props.theme.borderRadius};
  padding: 0 ${props => props.theme.inputPadding};
  border: 1px solid ${props => props.theme.colorLine};
  width: 100%;
  appearance: none;

  ${props =>
    props.label &&
    css`
      padding-top: 0.7rem;
    `};

  &::placeholder {
    color: ${props => props.theme.colorMuted};
  }

  &:focus {
    border-color: ${props => props.theme.colorPrimary};
    outline: none;
    box-shadow: none;

    + ${InputTextLabel} {
      font-size: 0.7em;
      margin-top: -0.65rem;
      color: ${props => props.theme.colorGlobal};
    }
  }

  ${props =>
    props.defaultValue &&
    css`
      + ${InputTextLabel} {
        font-size: 0.7em;
        margin-top: -0.65rem;
      }
    `};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${props =>
    props.error &&
    css`
      color: ${props => props.theme.colorRed};
      border-color: ${props => props.theme.colorRed};
      background-color: ${props => props.theme.colorRedExtraTransparent};

      &:focus {
        border-color: ${props => props.theme.colorRed};
        + ${InputTextLabel} {
          color: ${props => props.theme.colorRed};
        }
      }

      &::placeholder {
        color: ${props => props.theme.colorRed};
      }
      + ${InputTextLabel} {
        color: ${props => props.theme.colorRed};
      }
    `}

  ${props =>
    props.success &&
    css`
      color: ${props => props.theme.colorGreen};
      border-color: ${props => props.theme.colorGreen};
      background-color: ${props => props.theme.colorGreenTransparent};
      -webkit-text-fill-color: ${props.theme.colorGreen};

      &::placeholder {
        color: ${props => props.theme.colorGreen};
      }
    `}
`

const InputTextIcon = styled(Icon)`
  ${TransitionPrimary('transform, opacity, visibility')}
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translate3d(0, -50%, 0);
  display: block;
  line-height: 1;
  visibility: hidden;
  opacity: 0;
  transform: translate3d(0, calc(-50% - 0.5rem), 0);

  &.MuiIcon-root {
    font-size: 1.1rem;
  }

  ${props =>
    props.visible === 'true' &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, -50%, 0);
    `};

  ${props =>
    props.type === 'success' &&
    css`
      color: ${props.theme.colorGreen};
    `};
`
const InputText = React.forwardRef((props, ref) => {
  const { name, success, onBlur, onChange, label } = props

  const handleChange = event => {
    const value = event.target.value
    return onChange ? onChange({ name, value }) : false
  }
  const handleBlur = event => {
    const value = event.target.value
    return onBlur ? onBlur({ name, value }) : false
  }

  return (
    <InputTextWrapper>
      <InputTextControl {...props} onChange={handleChange} onBlur={handleBlur} ref={ref} />
      {label && <InputTextLabel>{label}</InputTextLabel>}
      <InputTextIcon type="success" visible={success + ''}>
        check_circle
      </InputTextIcon>
    </InputTextWrapper>
  )
})

export default InputText
