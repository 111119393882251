import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'
import { animate } from 'popmotion'
import { triangle } from 'polished'

const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.75rem;
  text-align: center;
  background-color: ${props => props.theme.colorBody};
  font-weight: ${props => props.theme.fontPrimaryMedium};
  margin-bottom: 1.75rem;
  text-align: center;

  &:after {
    ${triangle({ pointingDirection: 'bottom', width: '20px', height: '10px', foregroundColor: props => props.theme.colorBody })}
    content: ' ';
    position: absolute;
    left: calc(50% - 10px);
    top: 100%;
    display: block;
  }

  .MuiIcon-root {
    font-size: 1.1em;
    margin-right: 0.5rem;
  }
`

const TooltipInner = styled.span`
  display: block;
  white-space: nowrap;
`

const TooltipText = styled.span`
  margin-bottom: 1.75rem;

  .MuiIcon-root {
    vertical-align: middle;
    font-size: 1.1em;
    margin-right: 0.5rem;
  }
`

const Tooltip = forwardRef((props, ref) => {
  const [content, setContent] = useState(props.children)
  const inner = useRef()
  const text = useRef()

  useEffect(() => {
    inner.current.style.width = `${inner.current.clientWidth}px`
  }, [])

  useImperativeHandle(ref, () => ({
    updateTooltip(content) {
      animate({
        from: 1,
        to: 0,
        duration: 300,
        onUpdate: val => (inner.current.style.opacity = val),
        onComplete: () => {
          setContent(content)
          animate({
            from: 0,
            to: 1,
            elapsed: -300,
            duration: 300,
            onUpdate: val => (inner.current.style.opacity = val)
          })
          animate({
            from: inner.current.clientWidth,
            to: text.current.getBoundingClientRect().width,
            duration: 300,
            onUpdate: val => (inner.current.style.width = `${val}px`)
          })
        }
      })
    }
  }))

  return (
    <TooltipWrapper {...props}>
      <TooltipInner ref={inner}>
        <TooltipText ref={text}>{content}</TooltipText>
      </TooltipInner>
    </TooltipWrapper>
  )
})

Tooltip.defaultProps = {
  type: 'primary',
  size: 'default',
  shape: 'rounded'
}

export default Tooltip
