import { StepsList, StepsListItem, StepsListItemIcon, StepsListItemName, StepsProgress, StepsWrapper } from './Steps.styles'

import React from 'react'
import { useSelector } from 'react-redux'

const Steps = props => {
  const steps = useSelector(state => state.common.steps)
  const totalSteps = useSelector(state => state.common.totalSteps)
  const currentStep = useSelector(state => state.common.currentStep)

  return (
    <StepsWrapper>
      <StepsProgress progress={(currentStep / totalSteps) * 100} />
      <StepsList>
        {steps.map((step, index) => (
          <StepsListItem active={currentStep >= index + 1} key={`step_${index}`}>
            <StepsListItemIcon>{step.icon}</StepsListItemIcon>
            <StepsListItemName>{step.name}</StepsListItemName>
          </StepsListItem>
        ))}
      </StepsList>
    </StepsWrapper>
  )
}

export default Steps
