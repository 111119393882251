export const PAYMENT_NAMES = {
  'master-card': 'Master Card',
  diners: 'Diners',
  amex: 'Amex',
  visa: 'Visa',
  's-pankki-verkkomaksu': 'S-Pankki',
  'handelsbanken-e-payment': 'Handelsbanken',
  'aktia-maksu': 'Aktia',
  'nordea-e-payment': 'Nordea',
  'pop-pankin-verkkomaksu': 'Pop Pankki',
  'saastopankin-verkkomaksu': 'Säästöpankki',
  'alandsbanken-e-payment': 'Ålandsbanken',
  'oma-saastopankin-verkkomaksu': 'Oma Säästöpankki',
  'euroloan-invoice': 'Euroloan',
  mobilepay: 'MobilePay',
  'afterpay-invoice': 'AfterPay Lasku',
  siirto: 'Siirto',
  'sampo-web-payment': 'Sampo',
  'op-pohjola-verkkomaksu': 'OP Pohjola',
  'enterpay-invoice': 'Enterpay Lasku',
  'invoice-collector': 'Collector Lasku',
  'svea-webpay-invoice': 'Svea Lasku',
  'svea-webpay-installment': 'Svea Osamaksu'
}
