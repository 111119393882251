import styled, { keyframes } from 'styled-components'

import React from 'react'

const loaderRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const loaderTurn = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
`

const LoaderWrapper = styled.svg`
  stroke: ${props => props.theme.colorPrimary};
  animation: ${loaderRotate} 1.4s linear infinite;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  display: block;
`

const LoaderCircle = styled.circle`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-width: 3;
  transform-origin: center;
  animation: ${loaderTurn} 1.4s ease-in-out infinite;
`

const Loader = props => {
  return (
    <LoaderWrapper {...props} viewBox="25 25 50 50" xmlns="http://www.w3.org/2000/svg">
      <LoaderCircle fill="none" stroke-linecap="round" cx="50" cy="50" r="20"></LoaderCircle>
    </LoaderWrapper>
  )
}

Loader.defaultProps = {
  size: 32
}

export default Loader
