import 'swiper/swiper.scss'

import * as dayjs from 'dayjs'

import { CalendarDayArrow, CalendarDayName, CalendarDayNumber, CalendarDayWrapper, CalendarDaysList, CalendarEmpty, CalendarMonth, CalendarSlotPrice, CalendarSlotTime, CalendarSlotWrapper, CalendarSlotsList, CalendarSwiperWrapper, CalendarWrapper } from './Calendar.styles'
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { fetchCalendar, selectDay, selectSlot } from '@redux/actions/bookingActions'

import Icon from '@material-ui/core/Icon'
import { capitalize } from 'lodash'
import { connect } from 'react-redux'
import { useWindowWidth } from '@react-hook/window-size'

const Calendar = props => {
  const { calendar, calendarStatus, selectedDay, selectedDayIndex, selectedSlot, fetchCalendar, selectSlot, selectDay } = props
  const windowWidth = useWindowWidth()

  const [calendarSwiper, setCalendarSwiper] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isBeginning, setIsBeginning] = useState(true)
  const [activeMonth, setActiveMonth] = useState(dayjs())

  const handleSelectSlot = slot => {
    selectSlot(slot)
  }

  const handleSelectDay = (day, index) => {
    selectDay({ day, index })
    setActiveMonth(dayjs(day.date))
  }

  const slideChange = () => {
    if (calendarSwiper.isBeginning) {
      setIsBeginning(true)
    } else {
      setIsBeginning(false)
    }

    if (calendarSwiper.isEnd) {
      loadCalendar()
    }

    setActiveMonth(dayjs(calendar.days[calendarSwiper.activeIndex].date))
  }

  const loadCalendar = () => {
    const startDate = dayjs()
    const lastDay = dayjs(calendar.days[calendar.days.length - 1].date)
    const endDate = lastDay.add(1, 'month')

    fetchCalendar(startDate, endDate).then(() => {
      calendarSwiper.updateSlides()
    })
  }

  const goPrev = () => {
    calendarSwiper.slidePrev()
  }

  const goNext = () => {
    calendarSwiper.slideNext()
  }

  const setFirstAvailableDay = () => {
    if (selectedDay && !isDayAvailable(selectedDay)) {
      const days = calendar.days
      let i = 0

      while (i < days.length) {
        if (isDayAvailable(days[i])) {
          selectDay({ day: days[i], index: i })
          break
        }
        i++
      }
    }
  }

  const swiperSettings = {
    slidesPerView: 7,
    slidesPerGroup: windowWidth <= props.theme.breakpointMd ? 7 : 1,
    simulateTouch: windowWidth <= props.theme.breakpointMd
  }

  const isDayAvailable = day => {
    let isAvailable = false

    day.businessHours.map(hour => {
      if (hour.freeSlots.length > 0) {
        isAvailable = true
      }
    })

    return isAvailable
  }

  useEffect(() => {
    setFirstAvailableDay()
  }, [setFirstAvailableDay])

  useEffect(() => {
    setFirstAvailableDay()
  }, [calendar, setFirstAvailableDay])

  useEffect(() => {
    if (calendarSwiper) slideChange()
  }, [currentIndex, calendarSwiper])

  const CalendarDay = props => {
    return (
      <CalendarDayWrapper active={props.active} onClick={props.onClick} disabled={props.disabled}>
        <CalendarDayName>{props.name}</CalendarDayName>
        <CalendarDayNumber>{props.number}</CalendarDayNumber>
      </CalendarDayWrapper>
    )
  }

  const CalendarSlot = props => {
    return (
      <CalendarSlotWrapper active={props.active} onClick={props.onClick} disabled={props.disabled}>
        <CalendarSlotTime>{props.time}</CalendarSlotTime>
        <CalendarSlotPrice>{props.price}</CalendarSlotPrice>
      </CalendarSlotWrapper>
    )
  }

  return (
    <CalendarWrapper>
      {calendarSwiper && <CalendarMonth>{capitalize(activeMonth.format('MMMM YYYY'))}</CalendarMonth>}
      <CalendarDaysList>
        <CalendarDayArrow onClick={goPrev} visible={!isBeginning}>
          <Icon>arrow_back_ios</Icon>
        </CalendarDayArrow>
        <CalendarSwiperWrapper>
          <Swiper {...swiperSettings} onSlideChange={swiper => setCurrentIndex(swiper.activeIndex)} onSwiper={setCalendarSwiper}>
            {calendar.days &&
              calendar.days.map((day, index) => {
                const dayObj = dayjs(day.date)
                return (
                  <SwiperSlide key={`day_${dayObj.date()}_${index}`}>
                    <CalendarDay name={capitalize(dayObj.format('dd'))} number={dayObj.format('DD')} onClick={() => handleSelectDay(day, index)} active={selectedDayIndex === index && calendarStatus !== 'LOADING'} disabled={!isDayAvailable(day)} />
                  </SwiperSlide>
                )
              })}
          </Swiper>
        </CalendarSwiperWrapper>
        <CalendarDayArrow onClick={goNext} visible={true}>
          <Icon>arrow_forward_ios</Icon>
        </CalendarDayArrow>
      </CalendarDaysList>
      <CalendarSlotsList>
        {selectedDay &&
          isDayAvailable(selectedDay) &&
          selectedDay.businessHours.map((hour, index) =>
            hour.freeSlots.map((slot, index) => {
              return <CalendarSlot time={dayjs(slot.time).format('HH:mm')} price={`${slot.price}€`} active={selectedSlot && selectedSlot.time && selectedSlot.time === slot.time} onClick={() => handleSelectSlot(slot)} key={`slot_${index}`} />
            })
          )}
        {selectedDay && !isDayAvailable(selectedDay) && <CalendarEmpty>Ei vapaita aikoja...</CalendarEmpty>}
      </CalendarSlotsList>
    </CalendarWrapper>
  )
}

const mapStateToProps = state => ({
  calendar: state.booking.calendar,
  calendarStatus: state.booking.calendarStatus,
  selectedDay: state.booking.selectedDay,
  selectedDayIndex: state.booking.selectedDayIndex,
  selectedSlot: state.booking.selectedSlot
})

const mapDispatchToProps = dispatch => ({
  fetchCalendar: (startDate, endDate) => dispatch(fetchCalendar(startDate, endDate)),
  selectSlot: payload => dispatch(selectSlot(payload)),
  selectDay: payload => dispatch(selectDay(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
